import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';

import { deleteBoard, getDetailBoard } from 'api/board';
import adminCheck from 'utils/adminCheck';
import Banner from 'components/subBanner/Banner';
import NoticeDetail from 'pages/community/NoticeDetail';

import * as Style from "assets/styleComponent/product/detail";

import bannerImage01 from 'assets/images/02. 제품소개/banner_02.jpg';
import bannerImage02 from 'assets/images/03.설치사례/banner_03.png';

const Detail = () => {
    const nav = useNavigate();
    const { categoryCode, boardCode } = useParams();
    const [board, setBoard] = useState(null);
    const [isPdf, setIsPdf] = useState(false);

    const deleted = async () => {
        await deleteBoard({ boardType: categoryCode, i_board: boardCode });
        nav(`/pages/${categoryCode}/1`);
    }

    const formetFile = (url) => {
        var urlParts = url.split('/');
        return urlParts[urlParts.length - 1];
    }

    useEffect(() => {
        getDetailBoard({ boardType: categoryCode, i_board: boardCode }, setBoard);
    }, [nav])

    return (
        categoryCode === '4020'
            ? <NoticeDetail />
            : <Style.Container>
                <Banner pageSubTitle={
                    sessionStorage.getItem('pageCode') === '20'
                        ? 'Sales presentation'
                        : 'Installation case'
                } pageTitle={
                    sessionStorage.getItem('pageCode') === '20'
                        ? "제품소개"
                        : "설치사례"
                } bannerImage={
                    sessionStorage.getItem('pageCode') === '20'
                        ? bannerImage01
                        : bannerImage02
                } h2Color={'#e9f0df'} h3Color={'#fff'}></Banner>
                {
                    sessionStorage.getItem('pageCode') === '20'
                        ? <div className="wrap">
                            <div className="title">{sessionStorage.getItem('pageName')}</div>

                            <div className="content">
                                <div className='boardTitle t1'>
                                    <h3 dangerouslySetInnerHTML={{ __html: board?.detail.title }}></h3>
                                    <img src={board?.detail.thumbnail} alt="" />
                                    {
                                        board?.detail.pdfPath !== null &&
                                        board?.detail.pdfPath.length !== 0 &&
                                        <button onClick={() => { setIsPdf(true) }}>PDF 설명서 다운</button>
                                    }

                                </div>

                                <p dangerouslySetInnerHTML={{ __html: board?.detail.content }}></p>

                                <div className='goList'>
                                    <Link to={`/pages/${categoryCode}/1`}>목록</Link>
                                    {
                                        adminCheck(false) &&
                                        <>
                                            <button onClick={deleted} to={`/pages/${categoryCode}/1`}>삭제</button>
                                            <Link to={`/admin/product/modfiy/${categoryCode}/${boardCode}`}>수정</Link>
                                        </>
                                    }
                                </div>
                            </div>

                            <div className="pdfPopup" style={!isPdf ? { display: "none" } : { display: "block" }}>
                                <div onClick={() => { setIsPdf(false) }} className="bg"></div>
                                <div className="pdfList">
                                    <i onClick={() => { setIsPdf(false) }} className="fa-solid fa-xmark"></i>
                                    <h3>PDF 설명서 다운</h3>
                                    {
                                        board?.detail.pdfPath !== null &&
                                        board?.detail.pdfPath.map((a, i) => {
                                            return (
                                                <a href={a} key={i} download><i className="fa-solid fa-download"></i>{formetFile(a)}</a>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        : <div className="wrap">
                            <div className="title">{sessionStorage.getItem('pageName')}</div>

                            <div className="content">
                                <div className='boardTitle'>
                                    <h3 dangerouslySetInnerHTML={{ __html: board?.detail.title }}></h3>
                                    <h4>{board?.detail.create_date}</h4>
                                </div>

                                <p dangerouslySetInnerHTML={{ __html: board?.detail.content }}></p>

                                <div className='goList'>
                                    <Link to={`/pages/${categoryCode}/1`}>목록</Link>
                                    {
                                        adminCheck(false) &&
                                        <>
                                            <button onClick={deleted} to={`/pages/${categoryCode}/1`}>삭제</button>
                                            <Link to={`/admin/product/modfiy/${categoryCode}/${boardCode}`}>수정</Link>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                }
            </Style.Container >
    );
};

export default Detail;