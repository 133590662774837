import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';

import Banner from 'components/subBanner/Banner';
import { sendInquiry } from 'api/board.js';

import * as Style from "assets/styleComponent/customer/mail";

import bannerImage from 'assets/images/04.고객지원/banner_04.png';
import { useState } from 'react';

export default function Mail() {
    const [mailType, setMailType] = useState(true);
    const [agree, setAgree] = useState(false);
    const [company, setCompany] = useState('');
    const [name, setName] = useState('');
    const [tel, setTel] = useState('');
    const [mail, setMail] = useState('');
    const [product, setProduct] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [txt, setTxt] = useState('');
    const [contentCheck, setContentCheck] = useState([false, false, false, false]);
    const [file1, setFile1] = useState([]);
    const [file2, setFile2] = useState([]);

    useEffect(() => {
        setAgree(false);
        setCompany('');
        setName('');
        setTel('');
        setMail('');
        setProduct('');
        setTxt('');
        setSubTitle('');
        setContentCheck([false, false, false, false]);
        setFile1([]);
        setFile2([]);
    }, [mailType])

    useEffect(() => { }, [contentCheck])

    const setCheck = (num) => {
        setContentCheck(prevContentCheck => {
            const copyArr = [...prevContentCheck];
            copyArr[num] = !copyArr[num];
            return copyArr;
        });
    }

    const checkContent = (contentArr) => {
        const selectedContent = contentArr.filter((_, i) => contentCheck[i]);

        return selectedContent.join(',');
    }

    const onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        switch (name) {
            case "company":
                setCompany(value);
                break;
            case "name":
                setName(value);
                break;
            case "tel":
                setTel(value);
                break;
            case "mail":
                setMail(value);
                break;
            case "product":
                setProduct(value);
                break;
            case "subTitle":
                setSubTitle(value);
                break;
            case "txt":
                setTxt(value);
                break;
        }
    }

    const onFileChange = (e) => {
        const files = Array.from(e.target.files);
        // setFile1(files);
        const name = e.target.name;
        switch (name) {
            case "file1":
                setFile1(files);
                break;
            case "file2":
                setFile2(files);
                break;
        }
    }

    const mailBtn = (e) => {
        e.preventDefault();

        if (company === "" || name === "" || tel === "" || mail === "") {
            alert("필수 정보를 입력해주세요.");
        } else if (agree !== true) {
            alert("이용약관에 동의해주세요.");
        } else {
            const data = {
                title: mailType ? '견적문의' : 'A/S문의',
                company: company,
                name: name,
                tel: tel,
                mail: mail,
                product: product,
                subTitle: subTitle,
                problem: mailType ? checkContent(['유수분리', '구매(견적)문의', '슬러지', '악취']) : checkContent(['메인보드', '점검표시', '흡입/배출', '필터']),
                txt: txt,
                file1: file1,
                file2: file2
            }
            // console.log(data);
            sendInquiry(data);
        }
    }



    return (
        <Style.Container>
            <Banner pageSubTitle={"Customer support"} pageTitle={"고객지원"} bannerImage={bannerImage} h2Color={'#a5c8cf'} h3Color={'#000'}></Banner>
            <div className="wrap">
                <div className="title">
                    <label className={mailType ? "on" : ""} onClick={() => { setMailType(true) }}>
                        <input type="hidden" />
                        <span>구매(견적)문의</span>
                    </label>
                    <label className={!mailType ? "on" : ""} onClick={() => { setMailType(false) }}>
                        <input type="hidden" />
                        <span>A/S문의</span>
                    </label>
                </div>

                <div className='centerLine'>
                    <p className='box'>
                        <b>우리테크 고객문의 이용에 대한 안내</b> <br /><br />
                        제1장 총칙<br />
                        <br />
                        <br />
                        제1조(목적)<br />
                        본 약관은 우리테크 주식회사(이하 "회사"라 한다)가 제공하는 https://도메인 의 서비스(이하 "서비스" 라 한다)를 이용함에 있어 회사와 이용자의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.<br />
                        <br />
                        제2조(공지 및 준용)<br />
                        이 규정의 내용은 서비스 화면에 게시하거나 기타의 방법으로 공지함으로써 효력을 발생합니다.<br />
                        회사는 이 규정을 변경할 수 있으며, 변경된 규정은 제1항과 같은 방법으로 공지함으로써 효력을 발생합니다.<br />
                        <br />
                        제3조(규정 외 준칙)<br />
                        이 규정에 명시되지 않은 사항은 전기통신기본법, 전기통신사업법 및 기타 관련법령의 규정에 의합니다.<br />
                        <br />
                        제2장 서비스 이용계약<br />
                        <br />
                        <br />
                        제5조(이용계약의 성립)<br />
                        1. 이용계약은 이용자의 본 이용약관 내용에 대한 동의와 이용신청에 대하여 회사의 이용승낙으로 성립합니다.<br />
                        2. 본 이용약관에 대한 동의는 본 약관을 읽고 "우리테크 고객문의 이용약관에 동의하십니까?" 의 동의 여부에 표시를 한 후 문의하기 버튼을 누름으로써 의사표시를 한 것으로 간주합니다.<br />
                        <br />
                        제6 조(이용신청)<br />
                        1. 본 서비스의 이용자는 회사에서 요청하는 제반 정보(성명, 이메일)를 제공하여야 합니다.<br />
                        2. 타인의 명의(성명 및 이메일)를 도용하여 이용신청을 한 이용자는 관계법령에 따라 처벌을 받을 수 있습니다.<br />
                        <br />
                        제7조(이용신청의 승낙)<br />
                        1. 회사는 이용자의 이용 및 문의에 대하여 별도로 승인을 하지는 않습니다.<br />
                        <br />
                        제8조(계약사항의 변경)<br />
                        1. 고객문의 시 기재한 사항이 변경되었을 경우에는 다시 문의하거나 유선상 연락을 통하여 변경하면 됩니다.<br />
                        <br />
                        제 3 장 서비스 이용<br />
                        <br />
                        <br />
                        제9조(회사의 의무)<br />
                        1. 회사는 관련법과 본 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며, 본 약관이 정하는 바에 따라 지속적이고, 안정적으로 서비스를 제공하기 위하여 최선을 다하여야 합니다.<br />
                        2. 회사는 이용자가 안전하게 서비스를 이용할 수 있도록 이용자의 개인정보보호를 위한 보안시스템을 구축하며 개인정보 보호정책을 준수합니다.<br />
                        3. 회사는 수신거절의 의사를 명백히 표시한 이용자에 대해서는 이용자가 원하지 않는 영리목적의 광고성 전자우편(이메일)을 발송하지 않습니다.<br />
                        4. 회사는 이용자로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 적절한 절차를 거쳐 즉시 처리하여야 합니다. 다만, 즉시 처리가 곤란한 경우는 이용자에게 그 사유와 처리 일정을 통보하여야 합니다.<br />
                        <br />
                        제10조(회원 이용자ID와 비밀번호 관리에 대한 의무)<br />
                        본 서비스는 회원 가입을 별도로 받지 않고 있으므로 회원 이용자 ID 및 비밀번호를 수집하지 않습니다.<br />
                        <br />
                        제11조(서비스 전반에 관한 제보자의 의무)<br />
                        이용자는 서비스를 이용할 때 다음 각 호의 행위를 하지 않아야 합니다.<br />
                        ① 타인의 명의를 부정하게 사용하는<br />
                        ② 서비스에서 얻은 정보를 회사의 사전승낙 없이 이용자의 이용 이외의 목적으로 복제하거나 이를 출판 및 방송 등에 사용하거나 제 3 자에게 제공하는 행위<br />
                        ③ 제3자의 저작권 등 기타 권리를 침해하는 행위<br />
                        ④ 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형 등을 타인에게 유포하는 행위<br />
                        ⑤ 범죄와 결부된다고 객관적으로 판단되는 행위<br />
                        ⑥ 기타 관계법령에 위배되는 행위<br />
                        이용자는 이 규정에서 규정하는 사항과 서비스 이용안내 또는 주의사항을 준수하여야 합니다.<br />
                        이용자는 내용별로 회사가 서비스 공지사항에 게시하거나 별도로 공지한 이용제한 사항을 준수하여야 합니다<br />
                        <br />
                        제12조(정보의 제공)<br />
                        회사는 이용자가 서비스 이용 중 필요가 있다고 인정되는 다양한 정보에 대해서 전자우편, 유선매체, 서신우편 등의 방법으로 제보자에게 제공할 수 있습니다.<br />
                        <br />
                        제13조(제보자의 게시물)<br />
                        회사는 이용자가 문의한 내용에 대하여 절대 비밀로 유지하며 별도의 웹사이트에 게시하지 않습니다.<br />
                        <br />
                        제14조(서비스 이용시간)<br />
                        서비스의 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간을 원칙으로 합니다. 다만 정기점검 등의 필요로 회사가 정한 날이나 시간은 그러하지 않습니다.<br />
                        회사는 서비스를 일정 범위로 분할하여 각 범위 별로 이용 가능 시간을 별도로 정할 수 있으며 이 경우 그 내용을 사전에 공지합니다.<br />
                        <br />
                        제15조(서비스 제공의 중지)<br />
                        1. 회사는 다음 각 호에 해당하는 경우 서비스 제공을 중지할 수 있습니다.<br />
                        ① 서비스용 설비의 보수 등 공사로 인한 부득이한 경우<br />
                        ② 전기통신사업법에 규정된 기간통신사업자가 전기통신서비스를 중지했을 경우<br />
                        회사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 때에는 서비스의 전부 또는 일부를 제한하거나 정지할 수 있습니다.<br />
                        <br />
                        제 4 장 손해배상 등<br />
                        <br />
                        <br />
                        제16조(손해배상)<br />
                        회사는 서비스 요금이 무료인 동안의 서비스 이용과 관련하여 이용자에게 발생한 어떠한 손해에 관하여도 책임을 지지 않습니다<br />
                        <br />
                        제17조(면책조항)<br />
                        회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.<br />
                        회사는 이용자의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.<br />
                        회사는 이용자가 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관하여는 책임을 지지 않습니다.<br />
                        <br />
                        서비스 이용으로 발생한 분쟁에 대해 소송이 제기될 경우 회사의 본사 소재지를 관할하는 법원을 관할법원으로 합니다.
                    </p>
                    <div className='radioBox'>
                        <span>
                            ※개인정보 보호법에 의해 이용 약관에 동의를 하셔야 상담이 가능합니다. <br />
                            우리테크 고객문의 이용약관에 동의하십니까?
                        </span>
                        <label className='radio'>
                            <input type="radio" name="agree" id="agree" checked={agree ? true : false} onChange={() => {
                                setAgree(true);
                            }} />
                            동의
                        </label>
                        <label >
                            <input type="radio" name="agree" checked={!agree ? true : false} id="agree" onChange={() => {
                                setAgree(false);
                            }} />
                            비동의
                        </label>
                    </div>
                    <form>
                        <div className='t1'>
                            <label>
                                <span><b>*</b>업체명</span>
                                <input onChange={onChange} value={company} type="text" id='company' name='company' placeholder='업체명을 입력해주세요' />
                            </label>
                            <label>
                                <span><b>*</b>담당자</span>
                                <input onChange={onChange} value={name} type="text" id='name' name='name' placeholder='성함을 입력해주세요' />
                            </label>
                        </div>
                        <div className='t2'>
                            <label>
                                <span><b>*</b>연락처</span>
                                <input onChange={onChange} value={tel} type="text" id='tel' name='tel' placeholder='연락처를 입력해주세요' />
                            </label>
                            <label>
                                <span><b>*</b>이메일</span>
                                <input onChange={onChange} value={mail} type="text" id='mail' name='mail' placeholder='정확하게 입력해주세요' />
                            </label>
                        </div>

                        <div className='t4'>
                            <h2>해당제품</h2>
                            <div>
                                <label>
                                    <input type="checkbox" onChange={onChange} name='product' value='스마트 절삭유정제기' checked={product === '스마트 절삭유정제기'} />
                                    <span>스마트 절삭유정제기</span>
                                </label>
                                <label>
                                    <input type="checkbox" onChange={onChange} name='product' value='집진기 / 국소배기장치' checked={product === '집진기 / 국소배기장치'} />
                                    <span>집진기 / 국소배기장치</span>
                                </label>
                                <label>
                                    <input type="checkbox" onChange={onChange} name='product' value='절삭유 자동공급장치' checked={product === '절삭유 자동공급장치'} />
                                    <span>절삭유 자동공급장치</span>
                                </label>
                                <label>
                                    <input type="checkbox" onChange={onChange} name='product' value='찾아가는 정제 서비스' checked={product === '찾아가는 정제 서비스'} />
                                    <span>찾아가는 정제 서비스</span>
                                </label>
                                <label>
                                    <input type="checkbox" onChange={onChange} name='product' value='필터' checked={product === '필터'} />
                                    <span>필터</span>
                                </label>
                                <label>
                                    <input type="checkbox" onChange={onChange} name='product' value='절삭유 / 오일스키머' checked={product === '절삭유 / 오일스키머'} />
                                    <span>절삭유 / 오일스키머</span>
                                </label>
                            </div>
                        </div>
                        {
                            mailType
                                ?
                                <div className='t4'>
                                    <h2>문제점</h2>
                                    <div>
                                        <label>
                                            <input type="checkbox" onClick={() => { setCheck(0) }} checked={contentCheck[0]} />
                                            <span>유수분리</span>
                                            <span>{contentCheck[0]}</span>
                                        </label>
                                        <label>
                                            <input type="checkbox" onClick={() => { setCheck(1) }} checked={contentCheck[1]} />
                                            <span>구매(견적)문의</span>
                                        </label>
                                        <label>
                                            <input type="checkbox" onClick={() => { setCheck(2) }} checked={contentCheck[2]} />
                                            <span>슬러지</span>
                                        </label>
                                        <label>
                                            <input type="checkbox" onClick={() => { setCheck(3) }} checked={contentCheck[3]} />
                                            <span>악취</span>
                                        </label>
                                    </div>
                                </div>
                                : <div className='t4'>
                                    <h2>문제점</h2>
                                    <div>
                                        <label>
                                            <input type="checkbox" onClick={() => { setCheck(0) }} checked={contentCheck[0]} />
                                            <span>메인보드</span>
                                            <span>{contentCheck[0]}</span>
                                        </label>
                                        <label>
                                            <input type="checkbox" onClick={() => { setCheck(1) }} checked={contentCheck[1]} />
                                            <span>점검표시</span>
                                        </label>
                                        <label>
                                            <input type="checkbox" onClick={() => { setCheck(2) }} checked={contentCheck[2]} />
                                            <span>흡입/배출</span>
                                        </label>
                                        <label>
                                            <input type="checkbox" onClick={() => { setCheck(3) }} checked={contentCheck[3]} />
                                            <span>필터</span>
                                        </label>
                                    </div>
                                </div>
                        }
                        <div className='t9'>
                            <label>
                                <span>제목</span>
                                <input onChange={onChange} type="text" value={subTitle} id='subTitle' name='subTitle' placeholder='제목을 입력해주세요' />
                            </label>
                        </div>

                        <div className='t5'>
                            <label>
                                <span>문의 내용</span>
                                <textarea onChange={onChange} value={txt} name="txt" id="txt" placeholder='문의 내용을 입력해주세요' resize></textarea>
                            </label>
                        </div>
                        <div className='t6'>

                        </div>
                        <div className='t7'>
                            <label>
                                <span>파일 </span>
                                <input type="file" id='file1' name='file1' multiple onChange={onFileChange} />
                            </label>
                        </div>
                        <div className='t7'>
                            <label>
                                <span>파일 </span>
                                <input type="file" id='file2' name='file2' multiple onChange={onFileChange} />
                            </label>
                        </div>
                        <div className='t8'>
                            <button onClick={mailBtn}>문의하기</button>
                        </div>
                    </form>
                </div>
            </div>
        </Style.Container >
    )
}
