import React, { useEffect } from 'react';
import styled from "styled-components";

const PdfUpload = ({ title, setPdf, existingPdf, setExistingPdf }) => {
    const onFileChange = (e) => {
        const files = Array.from(e.target.files);
        setPdf(files);
    }

    const formetFile = (url) => {
        var urlParts = url.split('/');
        return urlParts[urlParts.length - 1];
    }

    const deleteFile = (num) => {
        const copyArr = [...existingPdf];
        copyArr.splice(num, 1);
        setExistingPdf(copyArr);
    }

    useEffect(() => { }, [existingPdf])

    return (
        <Div>
            <span dangerouslySetInnerHTML={{ __html: title }}></span>
            <div>
                <input type="file" multiple onChange={onFileChange} />
                {
                    existingPdf !== null &&
                    existingPdf !== undefined &&
                    existingPdf.length > 0 &&
                    existingPdf.map((a, i) => {
                        return (
                            <div className='files' key={i}>{formetFile(a)}
                                <div className="del" onClick={() => { deleteFile(i) }}>
                                    <i className="fa-solid fa-xmark"></i>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </Div>
    );
};

const Div = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 0px;
    border-bottom: 1px solid #ddd;
    span{
        display: inline-block;
        width: 150px;
    }
    img{
        display: block;
        padding-top: 10px;
        max-width: 300px;
    }
    .files{
        margin:5px 0px;
        display: flex;
        gap: 10px;
        i{
            color: red;
            cursor: pointer;
        }
    }
`;

export default PdfUpload;