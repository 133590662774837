import styled from "styled-components";

export const Fixed = styled.div`
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 10;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-top-left-radius:25px;
    border-bottom-left-radius:25px;
    box-shadow: 1px 1px 3px #00000044;
    a{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 30px;
        font-weight: bold;
        img{
            margin-bottom: 20px;
        }
    }
    @media (max-width:800px) {
        a{
            padding: 15px;
        }
    }
    @media (max-width:500px) {
        a{
            font-size: 10px;
            img{
                width: 30px;
            }
        }
    }
`

export const Banner = styled.div`
    position: relative;
    img{
        width: 100%;
    }
    .content{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: #fff;
        h2{
            font-size: 50px;
            font-family: 'GmarketSansBold';
            color: #60a99e;
            line-height: 1.2;
        }
        h3{
            font-size: 50px;
            font-family: 'GmarketSansBold';
            line-height: 1.2;
        }
        p{
            font-size: 19px;
            margin-top: 20px;
            color: #fdf8e5;
        }
    }
    @media (max-width:1000px) {
        .content h2{
            font-size: 30px;
        }
        .content p{
            font-size: 16px;
            margin-top: 10px;
        }
    }
    @media (max-width:800px) {
        .content h2{
            font-size: 24px;
        }
        .content p{
            font-size: 14px;
        }
    }

    @media (max-width:600px) {
        .content{
            top: 25%;
        }
    }
    @media (max-width:400px) {
        .content{
            top: 28%;
        }
        .content h2{
            font-size: 18px;
        }
        .content p{
            font-size: 12px;
        }
    }
`

export const Happy = styled.div`
    padding: 100px 0px;
    h3{
        font-size: 16px;
        color: #666;
        letter-spacing: -1px;
        color: #60a99e;
    }
    h2{
        font-size: 32px;
        font-family: 'GmarketSansBold';
        letter-spacing: -1px;
        margin: 15px 0px 30px;
    }
    ul{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 15px;
    }
    ul li{
        width: 100%;
    }
    ul li a {
        width: 100%;
        background-color: #dfeeec;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 30px 0px;
        border-radius: 15px;
    }
    ul li a p{
        font-size: 20px;
        margin-top: 40px;
        font-weight: bold;
    }

    .fax{
        text-align: center;
    }
    .fax p{
        background-color: #f3f8f7;
        display: inline-block;
        padding: 23px 50px 20px;
        border-radius: 10px;
        font-size: 16px;
        font-weight: bold;
        margin-top: 30px;
    }

    @media (max-width:800px) {
        h2{
            font-size: 24px;
            letter-spacing: -2px;
        }
        h3{
            font-size: 14px;
            letter-spacing: -1px;
            margin-top: 10px;
        }
        ul {
            grid-template-columns: 1fr;
        }
        ul li p{
            font-size: 18px;
        }
        .fax p{
            font-size: 14px;
            letter-spacing: -1px;
        }
    }
`


export const Slide = styled.div`
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 0px;
    h3{
        font-size: 16px;
        color: #666;
        letter-spacing: -1px;
        color: #60a99e;
    }
    h2{
        font-size: 32px;
        font-family: 'GmarketSansBold';
        letter-spacing: -1px;
        margin: 15px 0px 15px;
        color: #fff;
    }
    p{
        color: #fff;
        line-height: 1.2;
        margin-bottom: 50px;
    }
    a{
        width: 100%;
    }
    .slider{
        position: relative;
    }
    .slide{
        background-color: #ffffff22;
        padding: 30px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
    }
    .slide .img{
        height: 320px;
    }
    .slide h4{
        margin-top: 15px;
        font-weight: bold;
        font-size: 16px;
        color: #fff;
    }
    .pageing{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 110%;
        display: flex;
        justify-content: space-between;
        z-index: 5;
    }
    .pageing i{
        font-size: 34px;
        color: #fff;
        cursor: pointer;
    }
    @media (max-width:1200px) {
        .pageing{
            width: 100%;
        }
    }
`

export const Board = styled.div`
    padding: 100px 0px;
    ul{
        display: grid;
        grid-template-columns: 1.1fr 0.9fr;
        grid-gap: 20px;
        li{
            border: 2px solid #ccc;
            padding: 30px 20px;
            h2{
                color: #60a99e;
            }
            h3{
                font-size: 34px;
                font-weight: bold;
                margin-top: 20px;
            }
            .tap{
                display: flex;
                gap: 10px;
                div{
                    border: 1px solid #ccc;
                    padding: 7px 20px 5px;
                    border-radius: 30px;
                    cursor: pointer;
                }
                .on{
                    background-color: #60a99e;
                    border: 1px solid #60a99e;
                    color: #fff;
                }
            }
            .more{
                text-align: right;
                a{
                    font-size: 24px;
                    border: 1px solid #000;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    height: 28px;
                    width: 28px;
                    padding-top: 3px;
                    text-align: center;
                }
            }
            .board{
                border-top: 1px solid #000f;
                margin-top: 5px;
                >a,>div{
                    padding: 10px 0px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 20px;
                    :not(:last-child){
                        border-bottom: 1px solid #ccc;
                    }
                    .day{
                        font-size: 20px;
                        font-weight: bold;
                        color: #ccc;
                    }
                    .title{
                        flex: 1;
                        h4{
                            font-size: 20px;
                            font-weight: bold;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            line-height: 50px
                        }
                        p{
                            font-size: 16px;
                            color: #444;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            line-height: 1.2;
                        }
                    }
                    img{
                        width: 100px;
                        height: 50px;
                    }
                }
            }
        }
        li{
            
            &:nth-child(1){
                .board{
                    >a{
                        .title{
                            h4,
                            p{
                                width: 385px;
                            }
                        }
                    }
                }
            }
            &:nth-child(2){
                .board{
                    >div,a{
                        .day{
                            color: #60a99e;
                        }
                        .title{
                            h4,
                            p{
                                width: 439px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width:1200px) {
        ul{
            grid-template-columns: 1fr;
            li:nth-child(2) .board >a .title h4, 
            li:nth-child(2) .board >a .title p{
                width: 800px;
            }
        }
    }
    @media (max-width:900px) {
        ul{
            grid-template-columns: 1fr;
            li:nth-child(1) .board >a .title h4, 
            li:nth-child(1) .board >a .title p,
            li:nth-child(2) .board >a .title h4, 
            li:nth-child(2) .board >a .title p{
                width: 400px;
            }
            li .board >a img,
            li .board >div img{
                display: none;
            }
        }
    }
    @media (max-width:600px) {
        ul{
            grid-template-columns: 1fr;
            li{
                padding: 30px 15px;
                h3{
                    font-size: 24px;
                }
            }
            li:nth-child(1) .board >a .day,
            li:nth-child(2) .board >a .day{
                display: none;
            }
            li:nth-child(1) .board >a .title h4, 
            li:nth-child(1) .board >a .title p,
            li:nth-child(2) .board >a .title h4, 
            li:nth-child(2) .board >a .title p{
                width: 300px;
                font-size: 18px;
                line-height: 40px;
            }
            .flexBox{
                flex-direction: column;
                align-items: start;
                gap: 10px;
                h3{
                    font-size: 28px;
                    margin-top: 10px;
                }
            }
            .more{
                display: none;
            }
        }
    }
    @media (max-width:400px) {
        ul{
            grid-template-columns: 1fr;
            li:nth-child(1) .board >a .day,
            li:nth-child(2) .board >a .day{
                display: none;
            }
            li:nth-child(1) .board >a .title h4, 
            li:nth-child(1) .board >a .title p,
            li:nth-child(2) .board >a .title h4, 
            li:nth-child(2) .board >a .title p{
                width: 250px;
            }
            .flexBox{
                flex-direction: column;
                align-items: start;
                gap: 10px;
                h3{
                    font-size: 28px;
                    margin-top: 10px;
                }
            }
            .more{
                display: none;
            }
        }
    }
`