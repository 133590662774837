import styled from "styled-components";

export const Container = styled.div`
    .title{
        text-align: center;
        margin-bottom: 50px;
        font-size: 40px;
        font-family: 'GmarketSansBold';
        display: flex;
        gap: 15px;
        label{
            flex: 1;
            box-shadow: 0 2px 5px #00000033;
            border-radius: 15px;
            padding: 25px 0px 20px;
            span{
                font-size: 24px;
            }
        }
        .on{
            background-color: #06AB9F;
            color: #fff;
        }
    }

    .centerLine{
        width: 100%;
        margin: 0 auto;
        .box{
            border: 1px solid #a9a9a9;
            padding: 35px 40px 0px 40px;
            overflow-y: scroll;
            max-height: 300px;
            font-size: 16px;
            b{
                font-size: 20px;
            }
            align-self: center;
        }
        .radioBox{
            padding-top: 20px;
            font-size: 16px;
            label{
                padding-left: 10px;
            }
            span{
                line-height: 1.2;
            }
        }
        form{
            margin-top: 90px;
            padding-top: 20px;
            border-top: 2px solid #747474;
            div.t1, div.t2{
                display: flex;
                gap: 20px;
                margin-bottom: 20px;
                span{
                    vertical-align: bottom;
                    font-size: 18px;
                    b{
                        color: #06AB9F;
                        font-size: 16px;
                    }
                }
                label{
                    flex: 0.9;
                    background: #fafafa;
                    border-radius: 10px;
                    padding: 20px;
                    input{
                        border: 0px;
                        vertical-align: baseline;
                        background: #fafafa;
                        font-size: 17px;
                        padding-left: 20px;
                    }
                }
            }
            div.t3{
                background: #fafafa;
                margin-bottom: 20px;
                padding: 20px;
                border-radius: 10px; 
                span{
                    vertical-align: bottom;
                    font-size: 18px;
                }
                label{
                    input{
                        border: 0px;
                        vertical-align: baseline;
                        background: #fafafa;
                        font-size: 17px;
                        padding-left: 20px;
                    }
                }
            }
            
            div.t9{
                background: #fafafa;
                margin-bottom: 20px;
                padding: 20px;
                border-radius: 10px; 
                span{
                    vertical-align: bottom;
                    font-size: 18px;
                }
                label{
                    input{
                        border: 0px;
                        vertical-align: baseline;
                        background: #fafafa;
                        font-size: 17px;
                        padding-left: 20px;
                    }
                }
            }

            div.t4{
                background: #fafafa;
                margin-bottom: 20px;
                padding: 20px;
                border-radius: 10px; 
                display: flex;
                gap: 20px;
                h2{
                    font-size: 18px;
                }
                >div{
                    display: flex;
                    gap: 20px;
                    input{
                        margin-right: 5px;
                    }
                }
            }
            div.t5{
                background: #fafafa;
                margin-bottom: 20px;
                padding: 20px;
                border-radius: 10px; 
                span{
                    vertical-align: bottom;
                    font-size: 18px;
                }
                >label{
                    display: flex;
                    textarea{
                        margin-left: 20px;
                        width: 90%;
                        height: 320px;
                        border: 0px;
                        background: #fafafa;
                        font-size: 17px;
                        resize: none;
                        /* font-family: "GmarketSansMedium"; */
                    }
                }
                .checkBox{
                    display: flex;
                    flex-direction: column;
                    h2{
                        font-size: 18px;
                    }
                    label{
                        padding: 0px;
                        input{
                            margin-right: 5px;
                        }
                    }
                }
            }
            div.t6{
                height: 2px;
                background: #ebebeb;
                margin-top: 20px;
            }
            div.t7{
                margin-top: 20px;
                background: #fafafa;
                margin-bottom: 20px;
                padding: 20px;
                border-radius: 10px; 
                width: 50%;
                span{
                    vertical-align: baseline;
                    font-size: 18px;
                }
                label{
                    input{
                        border: 0px;
                        vertical-align: baseline;
                        background: #fafafa;
                        font-size: 17px;
                        padding-left: 20px;
                    }
                }
            }
            div.t8{
                display: flex;
                justify-content: center;
                margin-top: 50px;
                margin-bottom: 80px;
                button{
                    background: #06AB9F;
                    padding: 20px 80px;
                    border-radius: 30px;
                    color: #fff;
                    font-size: 20px;
                    cursor: pointer;
                }
            }
        }
    }
    @media (max-width:1200px) {
        .centerLine form div.t4 div{
            flex-direction: column;
            gap: 5px;
        } 
    }
    @media (max-width:1000px) {
        .centerLine form div.t5 label textarea {
            width: 80%;
        }
    }
    @media (max-width:800px) {
        .centerLine form div.t1, 
        .chdcsu .centerLine form div.t1, 
        form div.t2{
            flex-direction: column;
        }
        .centerLine form div.t7{
            width: 100%;
        }
    }
    @media (max-width:600px) {
        .title{
            display: flex;
            flex-direction: column;
            gap: 15px;
            label{
                padding: 20px 0px 15px;
                span{
                    font-size: 18px;
                }
            }
        }
        .centerLine .box{
            padding: 15px 20px 0px 20px;
            font-size: 14px;
            b{
                font-size: 18px;
            }
        }
        .centerLine .radioBox{
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        .centerLine form{
            margin-top: 40px;
        }
        .centerLine form > div > label {
            display: flex;
            flex-direction: column;
        }
        .centerLine form div span, input {
            font-size: 16px !important;
        }
        .centerLine form div label input,textarea {
            padding-left: 0px !important;
            padding-top: 10px;
        }
        .centerLine form div.t5 label textarea {
            margin-left: 0px;
        }
        
        .centerLine form div.t4 {
          flex-direction: column;
        } 
    }
    @media (max-width: 400px){
        .title{
            font-size: 28px;
        }
        .centerLine{
            width: 100%;
        }
        .centerLine .box{
            padding: 15px 15px 0px 15px;
            font-size: 12px;
            b{
                font-size: 15px;
            }
        }
        .centerLine form div label {
            padding: 10px !important;
        }
        .centerLine form div span, input {
            font-size: 14px !important;
        }
        .centerLine form div.t5 label textarea {
            font-size: 14px;
        }
        .centerLine form div.t3, div.t5, div.t6{
            padding: 0px !important;
        }
    }
`