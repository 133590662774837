import styled from "styled-components";

export const Content = styled.div`
    .content{
        img{
            width: 100%;
            z-index: -1;
        }
    }
    h3{
        font-size: 30px;
        text-align: center;
        font-family: 'GmarketSansBold';
        margin: 70px 0px 30px;
    }
    ul{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 15px;
        li{
            width: 100%;
            img{
                width: 100%;
            }
            p{
                border: 1px solid #06aba1;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
            }
        }
    }
    @media (max-width:600px) {
        .content{
            overflow: hidden;
            img{
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                width: 700px;
            }
        }
        h3{
            margin: 40px 0px 20px;
            font-size: 24px;
        }
    }
    @media (max-width:400px) {
        .content{
            img{
                width: 500px;
            }
        }
        ul{
            grid-template-columns: 1fr 1fr;
        }
    }
`