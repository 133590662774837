import React, { useEffect } from 'react'
import styled from "styled-components";
import Banner from 'components/subBanner/Banner';

import bannerImage from 'assets/images/01. 회사소개/banner_01.jpg'
import logo from 'assets/images/hd_logo.png'
import icon01 from 'assets/images/01. 회사소개/3.오시는 길/위치 아이콘.png'
import icon02 from 'assets/images/01. 회사소개/3.오시는 길/전화 아이콘.png'

const { kakao } = window;

export default function Loaction() {

    useEffect(() => {
        const container = document.getElementById('map');
        const location = new kakao.maps.LatLng(37.531780, 126.753187);
        const options = {
            center: location,
            level: 3
        };
        const kakaoMap = new kakao.maps.Map(container, options);
        const marker = new kakao.maps.Marker({
            position: location
        });
        marker.setMap(kakaoMap);
    }, [])

    return (
        <>
            <Banner pageSubTitle={'Company introduction'} pageTitle={'회사소개'} bannerImage={bannerImage}></Banner>
            <Map>
                <div className="wrap">
                    <div className="title">오시는 길</div>
                    <div id="map"></div>
                    <div className="info">
                        <img src={logo} alt="" />
                        <div>
                            <p><img src={icon01} alt="" />인천광역시 계양구 서운산단로1길19(서운동 222번지) DSE지식산업센터 5층 511,512호 </p>
                            <p><img src={icon02} alt="" />1566-7424</p>
                        </div>
                    </div>
                </div>
            </Map>
        </>
    )
}

const Map = styled.div`
    text-align: center;
    .title{
        text-align: center;
        margin-bottom: 50px;
        font-size: 40px;
        font-family: 'GmarketSansBold';
    }
    #map{
        width: 100%;
        height: 580px;
        border-radius: 20px;
        box-shadow: 5px 5px 5px rgba(0,0,0,0.1);
    }
    .info{
        background-color: #50ab9f;
        display: inline-block;
        position: relative;
        transform: translateY(-50%);
        z-index: 10;
        padding: 30px 100px;
        border-radius: 30px;
        p{
            text-align: left;
            font-size: 16px;
            color: #fff;
            display: flex;
            align-items: center;
            gap: 10px;
            margin-top: 20px;
            line-height: 1.2;
        }
    }
    @media (max-width:700px) {
        #map{
            height: 400px;
        }
        .info{
            padding: 30px 15px;
            p{
                margin-top: 10px;
            }
        }
    }
`