import styled from "styled-components";

export const Container = styled.div`
    .title{
        text-align: center;
        margin-bottom: 50px;
        font-size: 40px;
        font-family: 'GmarketSansBold';
    }
    .boardTitle{
        padding: 50px 0px 15px;
        text-align: center;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        position: relative;
        h3{
            text-align: center; 
            font-size: 24px;
            font-weight: bold;
        }
        h4{
            text-align: right;
            padding-right: 30px;
            font-size: 16px;
            margin-top: 20px;
        }
        img{
            margin-top:30px;
            max-width: 300px;
            width: 100%;
        }
        button{
            position: absolute;
            bottom: 15px;
            right: 30px;
            background-color: #7ad5cc;
            color: #fff;
            padding: 15px 30px 12px;
            border-radius: 50px;
            font-weight: 300;
            cursor: pointer;
        }
    }

    .boardTitle.t1{
        padding: 50px 0px 70px;
    }

    .content > p{
        border-bottom: 1px solid #000;
        padding: 30px 0px;
        text-align: center;
        img{
            max-width: 100%;
        }
    }
    .goList{
        text-align: center;
        margin: 50px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        a,button{
            border: 1px solid #7ad5cc;
            padding: 15px 70px 12px;
            border-radius: 50px;
            color: #7ad5cc;
            font-weight: bold;
            cursor: pointer;
        }
    }
    .pdfPopup{
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 10;
        .bg{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #00000055;
        }
        .pdfList{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            background-color: #fff;
            padding: 30px;
            width: 300px;
            border-radius: 5px;
            >i{
                position: absolute;
                top: 5px;
                right: 10px;
                font-size: 20px;
                cursor: pointer;
            }
            h3{
                text-align: center;
                margin-bottom: 20px;
                font-size: 18px;
            }
            a{
                display: block;
                line-height: 1.5;
                i{
                    margin-right: 10px;
                }
            }
        }
    }
@media (max-width: 800px) {
        .title{
            font-size: 30px;
            margin-bottom: 30px;
        }
        .boardTitle{
            padding: 15px;
        }
        .boardTitle button{
            right: unset;
            left: 50%;
            transform: translateX(-50%);
            white-space: nowrap;
        }
        .boardTitle h4{
            text-align: center;
            margin-top: 10px;
            padding-right: 0px;
        }
        .goList{
            flex-direction: column;
            a,button{
                width: 100%;
            }
        }
    }
`