import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import LoginInput from 'components/input/Input';

import * as Style from "assets/styleComponent/faq/faq";

import bannerImage from 'assets/images/04.고객지원/banner_04.png';
import Banner from 'components/subBanner/Banner';
import { addFaq } from 'api/board';

const FaqCreate = () => {
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");

    const onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        switch (name) {
            case "title":
                setTitle(value);
                break;
            case "content":
                setContent(value);
                break;
            default:
                break;
        }
    };

    return (
        <Style.CreateContainer>
            <Banner pageSubTitle={"Customer support"} pageTitle={"고객지원"} bannerImage={bannerImage} h2Color={'#a5c8cf'} h3Color={'#000'}></Banner>
            <div className="wrap">
                <div className="title">{sessionStorage.getItem('pageName')} 등록</div>
                <LoginInput type="text" name="title" value={title} placeholder="질문" onChange={onChange}></LoginInput>
                <textarea name="content" value={content} onChange={onChange} placeholder="답변"></textarea>

                <div className="button">
                    <button onClick={() => {
                        addFaq({
                            question: title,
                            answer: content.replace(/\r?\n/g, "<br>")
                        })
                    }}>글쓰기</button>
                </div>
            </div>
        </Style.CreateContainer>
    );
};

export default FaqCreate;