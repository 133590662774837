import styled from "styled-components";

export const Company = styled.div`
    margin-bottom: 50px;
    .title{
        text-align: center;
        margin-bottom: 50px;
        font-size: 40px;
        font-family: 'GmarketSansBold';
    }
    .intro{
        width: 100%;
        margin-bottom: 30px;
    }
    p{
        margin: 15px 0px;
        line-height: 1.2;
        font-size: 16px;
    }
    @media (max-width:1000px) {
        br{
            display: none;
        }
    }
    @media (max-width:500px) {
        .intro{
            margin-bottom: 15px;
        }
        p{
            font-size: 14px;
        }
    }
`
