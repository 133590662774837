import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from "styled-components";

import { getCategory } from 'api/category';
import Loading from 'components/loding/Loading';

const Banner = ({ pageSubTitle, pageTitle, bannerImage, h2Color, h3Color }) => {
    const nav = useNavigate();
    const [categorys, setCategorys] = useState(null);
    const [isCate, setIsCate] = useState(false);
    const [isSubCate, setIsSubCate] = useState(false);

    const pageSetSession = (pageCode, pageNameCode) => {
        for (let i = 0; i < categorys.length; i++) {
            if (Number(String(pageCode).substr(0, 2)) === categorys[i].cate_code) {
                sessionStorage.setItem('pageCode', categorys[i].cate_code);
                for (let j = 0; j < categorys[i].lowCategory.length; j++) {
                    if (categorys[i].lowCategory[j].cate_code === pageNameCode) {
                        sessionStorage.setItem('pageName', categorys[i].lowCategory[j].cate);
                    }
                }
                return;
            }
        }
    }

    const subMenus = () => {
        const arr = [];
        for (let i = 0; i < categorys.length; i++) {
            if (categorys[i].cate_code === Number(sessionStorage.getItem('pageCode'))) {
                arr.push(...categorys[i].lowCategory);
            }
        }
        return arr;
    }

    useEffect(() => {
        getCategory(setCategorys);
    }, [])

    useEffect(() => {
        setIsCate(false);
        setIsSubCate(false);
    }, [nav])

    return (
        categorys === null
            ? <Loading />
            : <BannerStyle>
                <div className='bannerImage'>
                    <img src={bannerImage} alt="" />
                </div>
                <div className="wrap">
                    <div className="content">
                        <h2 style={{ color: h2Color }}>{pageSubTitle}</h2>
                        <h3 style={{ color: h3Color }}>{pageTitle}</h3>
                    </div>
                    <div className="category">
                        <div className="tap01">
                            <span onClick={() => { setIsCate(!isCate) }}>{pageTitle}<i className="fa-solid fa-chevron-down"></i></span>
                            <ul style={isCate ? { border: '1px solid #06aa9f', maxHeight: `${categorys.length * 40}px` } : null}>
                                {
                                    categorys?.map((a, i) => {
                                        return (
                                            a.cate_code === 20 ||
                                                a.cate_code === 30
                                                ? <li onClick={() => {
                                                    pageSetSession(a.cate_code, Number(`${a.cate_code}10`));
                                                }} key={i}>
                                                    <Link to={`/pages/${(a.cate_code)}10/1`}>{a.cate}</Link>
                                                </li>
                                                : <li onClick={() => {
                                                    pageSetSession(a.cate_code, Number(`${a.cate_code}10`));
                                                }} key={i}>
                                                    <Link to={`/pages/${(a.cate_code)}10`}>{a.cate}</Link>
                                                </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div className="tap02">
                            <span onClick={() => { setIsSubCate(!isSubCate) }}>{sessionStorage.getItem('pageName')}<i className="fa-solid fa-chevron-down"></i></span>
                            <ul
                                style={isSubCate ? { border: '1px solid #06aa9f', maxHeight: `${subMenus().length * 40}px` } : null}>
                                {
                                    subMenus().map((a, i) => {
                                        return (
                                            String(a.cate_code).substr(0, 2) === "20" ||
                                                String(a.cate_code).substr(0, 2) === "30"
                                                ? <li onClick={() => {
                                                    pageSetSession(a.cate_code, a.cate_code);
                                                }} key={i} ><Link to={`/pages/${a.cate_code}/1`} dangerouslySetInnerHTML={{ __html: a.cate }}></Link></li>
                                                : <li onClick={() => {
                                                    pageSetSession(a.cate_code, a.cate_code);
                                                }} key={i} ><Link to={a.cate === '뉴스/공지사항' ? `/pages/${a.cate_code}/1` : `/pages/${a.cate_code}`} dangerouslySetInnerHTML={{ __html: a.cate }}></Link></li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </BannerStyle >
    );
};

const BannerStyle = styled.div`
    position: relative;
    margin-bottom: 50px;
    .bannerImage{
        >img{
            width: 100%;
        }
    }
    .content{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        h2{
            font-size: 24px;
            font-family: 'GmarketSansBold';
            color: #c1cead;
        }
        h3{
            font-size: 50px;
            font-family: 'GmarketSansBold';
            color: #393939;
            margin-top: 20px;
        }
    }
    .category{
        position: relative;
        top: -30px;
        width: 100%;
        display: flex;
        gap: 50px;
        justify-content: center;
        align-items: center;
        background-color: #06aa9f;
        border-radius: 50px;
        > div{
            width: 300px;
            position: relative;
            span{
                width: 100%;
                display: inline-block;
                padding-left: 15px;
                line-height: 60px;
                color: #fff;
                position: relative;
                cursor: pointer;
                i{
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    transform: translateY(-50%);
                    font-size: 18px;
                }
            }
            ul{
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-top: none !important;
                background-color: #fff;
                max-height: 0px;
                overflow: hidden;
                transition: max-height .2s;
                li{
                    width: 100%;
                    a{
                        width: 100%;
                        padding-left: 20px;
                        font-size: 16px;
                        line-height: 40px;
                        color: #666;
                        :hover{
                            color: #06aa9f;
                        }
                    }
                }
            }
        }
    }
    @media (max-width:1000px) {
    .bannerImage{
        overflow: hidden;
            >img{
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                width: 1000px;
            }
        }
    }
    @media (max-width:600px) {
        .content h2 {
            font-size: 14px;
        }
        .content h3 {
            font-size: 30px;
            margin-top: 10px;
        }
        .category {
            gap:15px;
            top: -20px;
            > div{
                width: 100%;
                span{
                    text-align: center;
                    padding-left: 0px;
                    line-height: 40px;
                }
                ul{
                    width: 80%;
                    li{
                        a{
                            text-align: center;
                            padding-left: 0px;
                        }
                    }
                }
            }
        }
    }
    @media (max-width:500px) {
        .category {
            gap:15px;
            top: -16.5px;
            > div{
                width: 100%;
                span{
                    text-align: center;
                    padding-left: 0px;
                    line-height: 30px;
                    padding-top: 3px;
                    letter-spacing: -1px;
                    font-size:12px;
                    i{
                        font-size: 12px;
                    }
                }
                ul{
                    li{
                        a{
                            text-align: center;
                            padding-left: 0px;
                            font-size: 12px;
                            letter-spacing: -1px;
                            line-height: 30px;
                        }
                    }
                }
            }
        }
    }
`

export default Banner;