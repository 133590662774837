import React from 'react';

import Banner from 'components/subBanner/Banner';

import * as Style from "assets/styleComponent/intro/intro";

import bannerImage from 'assets/images/01. 회사소개/banner_01.jpg'
import intro from 'assets/images/01. 회사소개/1.회사소개/회사소개 이미지.jpg'


export default function Intro() {
    return (
        <>
            <Banner pageSubTitle={'Company introduction'} pageTitle={'회사소개'} bannerImage={bannerImage}></Banner>
            <Style.Company>
                <div className="wrap">
                    <div className="title">회사소개</div>
                    <img className='intro' src={intro} alt="" />
                    <p>
                        우리테크는 제조공장과 제조시설의 열악한 환경을 개선하기 위해 지난 2008년부터 현재까지 수십년간 현장을 직접 누비며 <br />
                        공장시설 및 환경개선을 위해 최선을 다해 절삭유정제기라는 명사를 직접 탄생시킨 절삭유정제기의 원조이며 관련 분야 선두기업입니다.
                    </p>

                    <p>
                        우리테크에서 개발하고 판매중인 절삭유정제기는 2008년 최초개발 및 판매를 시작으로 지금까지 기술 고도화된 제품을 개발하여 다양한 장비를 공급하고 있습니다.<br />
                        절삭유정제기 장치는 수용성 절삭유를 사용하는 모든 곳에서 공통으로 가지고 있는 절삭유의 오염물질을 제거하여 공장환경은 물론<br />
                        자연 환경 개선에까지 도움을 주며 비용이 절감되고 안전에도 영향을 미치는 중요한 사업입니다.
                    </p>

                    <p>
                        절삭유의 오염물질은 크게 3가지로 구분 지을 수 있습니다.
                    </p>


                    <p>
                        첫째, 공작기계 작동과 소재가공 시 발생되는 습동유, 작동유, 유압유 등 오일류가 절삭유에 유입되어 절삭유와 오일이 섞여서 절삭유 본래 기능을 못하게 되고<br />
                        가공 면의 불량이나 공구마모, 기계 고장까지 여러 가지 문제가 발생하는 부분을 해결 할 수 있습니다.
                    </p>

                    <p>
                        둘째, 가공 시 미세칩이나 미세 슬러지가 발생하여 절삭탱크로 유입되고 유입된 미세칩이 다시 분사되어 소재에 묻게 되면 가공정밀도에 문제가 발생하고,<br />
                        공작기계 관절 및 노즐 등에 들어 가게 되면 가공 중에 기계가 정지하는 등 심각한 고장을 일으키는 원인이 되기도 합니다. 지금까지 이러한 미세칩을 절삭유와 함께<br />
                        일정 기간 사용하다 스크랩이나 폐유로 처리하였으나 우리테크의 절삭유정제기는 절삭탱크에서 미세칩을 효율적으로 제거하여 절삭유 자원을 재순환 시키고<br />
                        사용자의 안전에도 좋은 영향을 가져올 수 있습니다.
                    </p>

                    <p>
                        셋째, 공작기계에 사용되는 절삭유의 특성상 절삭유의 부패가 빨라져 부패로 인한 악취가 발생되며 이로 인한 투통과 구토등이 발생하여 작업환경이 열악해집니다.<br />
                        우리테크의 절삭유정제기는 악취의 원인균을 비활성화 시켜 쾌적하고 깨끗한 작업환경을 기대할 수 있습니다.
                    </p>

                    <p>
                        우리테크는 보다 앞선 기술로 장비 개발과 기술 고도화를 위해 힘쓰고 투자를 아끼지 않는 기술 혁신 기업이 되도록 최선을 다하겠습니다.
                    </p>
                </div>
            </Style.Company></>
    )
}