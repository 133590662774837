import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import * as Style from "assets/styleComponent/main/main";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import SwiperCore, { EffectFade, Autoplay, Pagination, Navigation } from 'swiper';

import { getBoard, getByLatest, getFaq } from 'api/board';
import { getCategory } from 'api/category';

import icon01 from 'assets/images/main/icon01.png';
import icon02 from 'assets/images/main/icon02.png';
import icon03 from 'assets/images/main/icon03.png';
import banner01 from 'assets/images/main/banner01.jpg';
import m_banner01 from 'assets/images/main/m_banner01.jpg';
import banner02 from 'assets/images/main/banner02.jpg';
import m_banner02 from 'assets/images/main/m_banner02.jpg';
import banner03 from 'assets/images/main/banner03.jpg';
import m_banner03 from 'assets/images/main/m_banner03.jpg';
import happy01 from 'assets/images/main/m_1.png';
import happy02 from 'assets/images/main/m_2.png';
import happy03 from 'assets/images/main/m_3.png';
import slideBG from 'assets/images/main/slideBG.jpg';
import slide01 from 'assets/images/main/slide01.png';
import slide02 from 'assets/images/main/slide02.png';
import slide03 from 'assets/images/main/slide03.png';
import slide04 from 'assets/images/main/slide04.png';
import slide05 from 'assets/images/main/slide05.png';
import slide06 from 'assets/images/main/slide06.png';

SwiperCore.use([EffectFade, Autoplay, Pagination, Navigation]);

const Main = () => {
    const [categorys, setCategorys] = useState(null);
    const [board01, setBoard01] = useState(null);
    const [board02, setBoard02] = useState(null);
    const [board03, setBoard03] = useState(null);
    const [boardType, setBoardType] = useState(0);

    const newDate = (date) => {
        const dateParts = date.split('/');
        const month = dateParts[1];
        const day = dateParts[0];
        return month + '.' + day;
    }

    const pageSetSession = (pageCode, pageNameCode) => {
        for (let i = 0; i < categorys.length; i++) {
            if (pageCode === categorys[i].cate_code) {
                sessionStorage.setItem('pageCode', categorys[i].cate_code);
                for (let j = 0; j < categorys[i].lowCategory.length; j++) {
                    if (categorys[i].lowCategory[j].cate_code === pageNameCode) {
                        sessionStorage.setItem('pageName', categorys[i].lowCategory[j].cate);
                    }
                }
                return;
            }
        }
    }

    useEffect(() => {
        getCategory(setCategorys);
    }, [])

    useEffect(() => {
        getByLatest(setBoard01);
        getBoard({ boardType: "4020", boardPage: "1" }, setBoard02);
        getFaq(setBoard03);
    }, [])
    return (
        <main>
            <Style.Fixed className="fixed">
                <a href="tel:1566-7424">
                    <img src={icon01} alt="" />
                    온라인상담
                </a>
                <a href="https://open.kakao.com/o/sOLnSmFf" target={'_blank'}>
                    <img src={icon02} alt="" />
                    카톡상담
                </a>
                <Link to="/pages/4010">
                    <img src={icon03} alt="" />
                    견적문의
                </Link>
            </Style.Fixed>
            <Style.Banner className="banner">
                <div className="slider">
                    <Swiper
                        loop={true}
                        autoplay={{
                            "delay": 2500,
                            "disableOnInteraction": false,
                        }}
                    >
                        <SwiperSlide>
                            <img className='pcimg' src={banner01} alt="" />
                            <img className='moimg' src={m_banner01} alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img className='pcimg' src={banner02} alt="" />
                            <img className='moimg' src={m_banner02} alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img className='pcimg' src={banner03} alt="" />
                            <img className='moimg' src={m_banner03} alt="" />
                        </SwiperSlide>
                    </Swiper>
                </div>
                {/* <div className="wrap">
                    <div className="content">
                        <h2>환경기업전문</h2>
                        <h3>스마트 절삭유정제기</h3>
                        <p>그린뉴딜기업&혁신형 중소기업&규제샌드박스 통과</p>
                    </div>
                </div> */}
            </Style.Banner>
            <Style.Happy className="happy">
                <div className="wrap">
                    <h3>CONSULTATION REQUEST</h3>
                    <h2>스마트 절삭유정제기 제품문의</h2>

                    <ul>
                        <li>
                            <a href='tel:1566-7424'>
                                <img src={happy01} alt="" />
                                <p>전화상담 1566-7424</p>
                            </a>
                        </li>
                        <li>
                            <a href='https://open.kakao.com/o/sOLnSmFf' target={'_blank'}>
                                <img src={happy02} alt="" />
                                <p>온라인 상담 문의</p>
                            </a>
                        </li>
                        <li>
                            <Link to={`/pages/4010`}>
                                <img src={happy03} alt="" />
                                <p>견적문의</p>
                            </Link>
                        </li>
                    </ul>
                    <div className="fax">
                        <p>FAX : 032-435-2122 / E-mail : woori7424@daum.net</p>
                    </div>
                </div>
            </Style.Happy>
            <Style.Slide style={{ backgroundImage: `url('${slideBG}')` }}>
                <div className="wrap">
                    <h3>PRODUCTSLINE UP</h3>
                    <h2>스마트 절삭유정제기</h2>
                    <p>
                        폐유 처리, 비용절감은 물론 온실가스 감소로 <br />
                        친환경적 솔루션입니다.
                    </p>
                    <div className="slider">

                        <Swiper
                            loop={true}
                            slidesPerView={1}
                            spaceBetween={15}
                            navigation={{
                                nextEl: '.next',
                                prevEl: '.prev',
                            }}
                            className="mainSwiper"
                            breakpoints={{
                                1400: {
                                    slidesPerView: 4,
                                },
                                900: {
                                    slidesPerView: 3,
                                },
                                600: {
                                    slidesPerView: 2,
                                },
                                400: {
                                    slidesPerView: 1
                                }

                            }}
                            autoplay={{
                                "delay": 2500,
                                "disableOnInteraction": false,
                            }}
                        >
                            <SwiperSlide>
                                <Link to={"/pages/2010/detail/52"}>
                                    <div className='slide'>
                                        <div className="img">
                                            <img src={slide01} alt="" />
                                        </div>
                                        <h4>WM-2200 SMART</h4>
                                    </div>
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Link to={"/pages/2010/detail/51"}>
                                    <div className='slide'>
                                        <div className="img">
                                            <img src={slide02} alt="" />
                                        </div>
                                        <h4>WM-2200</h4>
                                    </div>
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Link to={"/pages/2010/detail/50"}>
                                    <div className='slide'>
                                        <div className="img">
                                            <img src={slide03} alt="" />
                                        </div>
                                        <h4>WMA-500A</h4>
                                    </div>
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Link to={"/pages/2010/detail/49"}>
                                    <div className='slide'>
                                        <div className="img">
                                            <img src={slide04} alt="" />
                                        </div>
                                        <h4>WMA-500B</h4>
                                    </div>
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Link to={"/pages/2010/detail/48"}>
                                    <div className='slide'>
                                        <div className="img">
                                            <img src={slide05} alt="" />
                                        </div>
                                        <h4>WMA-500CLA SMART</h4>
                                    </div>
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Link to={"/pages/2010/detail/47"}>
                                    <div className='slide'>
                                        <div className="img">
                                            <img src={slide06} alt="" />
                                        </div>
                                        <h4>WMA-500CLB SMART</h4>
                                    </div>
                                </Link>
                            </SwiperSlide>
                        </Swiper>

                        <div className="pageing">
                            <div className="prev">
                                <i className="fa-solid fa-chevron-left"></i>
                            </div>
                            <div className="next">
                                <i className="fa-solid fa-chevron-right"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </Style.Slide>
            <Style.Board>
                <div className="wrap">
                    <ul>
                        <li>
                            <h2>INSTALLATION CASE</h2>
                            <h3>설치사례</h3>
                            <div className="more">
                                <Link to={`/pages/3010/1`}>+</Link>
                            </div>
                            <div className="board">
                                {
                                    board01 !== null &&
                                        board01.length > 0
                                        ? board01.map((a, i) => {
                                            return (
                                                <Link to={`/pages/${a.cate_code}/detail/${a.i_board}`} onClick={() => {
                                                    pageSetSession(Number(String(a.cate_code).substr(0, 2)), a.cate_code);
                                                }} key={i}>
                                                    <div className="day">{newDate(a.create_date)}</div>
                                                    <div className="title">
                                                        <h4>{a.title}</h4>
                                                    </div>
                                                    <img src={a.thumbnail} alt="" />
                                                </Link>
                                            )
                                        })
                                        : <p style={{ textAlign: "center", marginTop: "20px", width: "100%" }}>등록된 게시글이없습니다</p>
                                }
                            </div>
                        </li>
                        <li>
                            <h2>CUSTOMER SUPPORT</h2>
                            <div className="flexBox">
                                <h3>고객지원</h3>
                                <div className="tap">
                                    <div onClick={() => { setBoardType(0) }} className={boardType === 0 ? 'on' : ''}>뉴스/공지사항</div>
                                    <div onClick={() => { setBoardType(1) }} className={boardType === 1 ? 'on' : ''}>FAQ</div>
                                </div>
                            </div>
                            <div className="more">
                                {
                                    boardType === 0
                                        ? <Link to={`/pages/4020/1`}>+</Link>
                                        : <Link to={`/pages/4030`}>+</Link>
                                }

                            </div>
                            {
                                boardType === 0
                                    ? <div className="board">
                                        {
                                            board02 !== null &&
                                                board02.list.length > 0
                                                ? board02.list.slice(0, 3).map((a, i) => {
                                                    return (
                                                        <Link to={`/pages/4020/detail/${a.i_board}`} key={i}>
                                                            <div className="day">0{i + 1}</div>
                                                            <div className="title">
                                                                <h4>{a.title}</h4>
                                                            </div>
                                                        </Link>
                                                    )
                                                })
                                                : <p style={{ textAlign: "center", marginTop: "20px", width: "100%" }}>등록된 게시글이없습니다</p>
                                        }
                                    </div>
                                    : <div className="board">
                                        {
                                            board03 !== null &&
                                                board03.length > 0
                                                ? board03.slice(0, 3).map((a, i) => {
                                                    return (
                                                        <Link to={`/pages/4030`} key={i}>
                                                            <div className="day">0{i + 1}</div>
                                                            <div className="title">
                                                                <h4>{a.question}</h4>
                                                            </div>
                                                        </Link>
                                                    )
                                                })
                                                : <p style={{ textAlign: "center", marginTop: "20px", width: "100%" }}>등록된 게시글이없습니다</p>
                                        }
                                    </div>
                            }
                        </li>
                    </ul>
                </div>
            </Style.Board>
        </main >
    );
};

export default Main;