import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getCategory } from 'api/category';
import logo from 'assets/images/ft_logo.png'

const Footer = () => {
    const [categorys, setCategorys] = useState(null);

    useEffect(() => {
        getCategory(setCategorys);
    }, [])

    return (
        <Foot>
            <div className="wrap">
                <ul>
                    <li className='left'>
                        <h2><img src={logo} alt="" /></h2>
                        <p><b>주소</b> 인천광역시 계양구 서운동 222번지 DSE지식센터 511, 512호</p>
                        <div className='grid'>
                            <p><b>전화번호</b> 1566-7424</p>
                            <p><b>팩스</b> 032-435-2122</p>
                            <p><b>이메일</b> woori7424@daum.net</p>
                        </div>
                        <div className="info">
                            <div>
                                <h3>Account Info</h3>
                                <p><b>우리은행</b> 1005-703-854379</p>
                                <p><b>기업은행</b> 501-035162-01-019</p>
                            </div>
                            <div>
                                <h3>CS Center</h3>
                                <h4>1566-7424</h4>
                                <span>MON-FRI 09:00~18:00</span>
                            </div>
                        </div>
                    </li>
                    <li className='right'>
                        <a href="http://woorien.net/" target={'_blank'}>
                            Family Site
                            <i className="fa-solid fa-arrow-up-right-from-square"></i>
                        </a>
                        <p>CopyrightⓒAES Eechnology All rights reserved.</p>
                    </li>
                </ul>
            </div>
        </Foot>
    )
};
const Foot = styled.footer`
    width: 100%;
    background-color: #f5f5f5;
    padding: 50px 0px;
    .wrap > ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .left {
        flex: 1;
    }
    .left > h2{
        margin-bottom: 20px;
    }
    .left p{
        color: #888;
    }
    .left p b{
        color: #000;
    }
    .left p,
    .left p b{
        line-height: 1.3;
    }

    .grid{
        display: flex;
        gap: 30px;
        margin-top: 20px;
    }

    .info{
        display: flex;
        gap: 30px;
        margin-top: 25px;
        h3{
            font-weight: bold;
            font-size: 14px;
        }
        h4{
            font-size: 28px;
            font-weight: bold;
            margin-top: 10px;
        }
        p{
            margin-top: 10px;
        }
        span{
            display: inline-block;
            margin-top: 10px;
            color: #888;
        }
    }

    .right{
        text-align: right;
    }
    .right a{
        border: 1px solid #000;
        padding: 15px;
        font-weight: bold;
        margin-bottom: 70px;
        i{
            margin-left: 100px;
        }
    }
    
    .right p{
        text-align: right;
        margin-top: 20px;
        color: #888;
    }

    @media (max-width:1000px) {
        .wrap > ul {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
        .grid{
            flex-direction: column;
            margin-top: 5px;
            gap: 5px;
        }
        .info{
            flex-direction: column;
        }
        .right{
            text-align: center;
        }
        .right a{
            margin-bottom: 0px;
            margin-top: 20px;
        }
        .right p{
            text-align: center;
        }
    }
    @media (max-width:500px) {
        .left p,
        .left p b{
            font-size: 12px;
        }
    }
`

export default Footer;