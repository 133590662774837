import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { updateProduct } from 'api/product.js';
import { getDetailBoard } from 'api/board';
import { getCategory } from 'api/category.js';

import ImageUpload from 'components/admin/product/input/ImageUpload';
import ProductInput from 'components/admin/product/input/Input';
import ProductSelect from 'components/admin/product/input/Select';
import Textarea from 'components/admin/product/input/Textarea';
import ProductOption from 'components/admin/product/input/ProductOption';
import PdfUpload from 'components/admin/product/input/PdfUpload';
import DefaultDiv from 'components/admin/product/input/DefaultDiv';
import Loading from 'components/loding/Loading';

import * as Common from 'assets/styleComponent/admin/common';
import * as Style from 'assets/styleComponent/admin/product/edit';

const Modfiy = () => {
    const { categoryCode, boardCode } = useParams();
    const [getProduct, setProduct] = useState(null);
    const [productName, setProductName] = useState("");
    const [cate01, setCate01] = useState("");
    const [cate02, setCate02] = useState("");
    const [firstCategory, setFirstCategory] = useState([]);
    const [secondCategory, setSecondCategory] = useState([]);
    const [price, setPrice] = useState("");
    const [discount, setDiscount] = useState("");
    const [sell, setSell] = useState("");
    const [stock, setStock] = useState("");
    const [thumbnail, setThumbnail] = useState("");
    const [pdf, setPdf] = useState([]);
    const [existingPdf, setExistingPdf] = useState([]);
    const [productContent, setProductContent] = useState("");
    const [productOption, setProductOption] = useState("");
    const [imageCode, setImageCode] = useState([]);
    const [optionData, setOptionData] = useState([]);
    const [folderPath, setFolderPath] = useState("");

    useEffect(() => {
        getCategory(setFirstCategory);
        getDetailBoard({ boardType: categoryCode, i_board: boardCode }, setProduct);
    }, [])

    useEffect(() => {
        if (getProduct !== null) {
            getProductData();
        }
    }, [getProduct])

    useEffect(() => {
        // 카테고리 1번 선택하면 2번에 카테고리 1번 하위 카테고리 가져오기
        for (let i = 0; i < firstCategory.length; i++) {
            if (firstCategory[i].cate_code === Number(cate01)) {
                setSecondCategory(firstCategory[i].lowCategory);
            }
        }
    }, [cate01])

    const getProductData = () => {
        let str = String(categoryCode);
        let firCate = str.slice(0, 2);

        setProductName(getProduct.detail.title);
        setCate01(firCate);
        setCate02(str);
        setThumbnail(getProduct.detail.thumbnail);
        setProductContent(getProduct.detail.content);
        setExistingPdf(getProduct.detail.pdfPath);
        setFolderPath(getProduct.detail.folder_path);
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        if (productName === "") {
            alert("제목 입력해주세요");
            return;
        } else if (cate01 === "선택해주세요" || cate01 === "") {
            alert("카테고리 1번 선택해주세요");
            return;
        } else if (cate02 === "선택해주세요" || cate02 === "") {
            alert("카테고리 2번 선택해주세요");
            return;
        }

        //에디터 실제로 이미지 있는지없는지 확인하고 없으면 지워줌
        let arr = imageCode;
        for (let i = 0; i < imageCode.length; i++) {
            for (let j = 0; j < arr.length; j++) {
                if (productContent.indexOf(imageCode[i]) === -1) {
                    if (arr[j] === imageCode[i]) {
                        arr.splice(j, 1);
                        j--;
                    }
                }
                setImageCode(arr);
            }
        }

        const data = {
            i_board: getProduct.detail.i_board,
            title: productName,
            cate_code: cate02 === "" || cate02 === "선택해주세요" ? cate01 : cate02,
            goods_img: thumbnail,
            content: productContent,
            image_code: imageCode,
            pdf: pdf,
            existingPdf: existingPdf,
            type: "product",
            folder_path: folderPath
        }

        updateProduct(data);
    }

    const onChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        switch (name) {
            case "prodctName":
                setProductName(value);
                break;
            case "firstCategory":
                setCate01(value);
                setCate02("");
                setSecondCategory([]);
                break;
            case "secondCategory":
                setCate02(value);
                break;
            case "price":
                setPrice(value);
                break;
            case "discount":
                setDiscount(value);
                break;
            case "stock":
                setStock(value);
                break;
            case "sell":
                setSell(value);
                break;

            default:
                break;
        }
    };



    return (
        getProduct === null
            ? <Loading />
            : <>
                <form onSubmit={onSubmit}>
                    <Common.Container>
                        <Style.H2>기본 설정</Style.H2>
                        <ProductInput title="제목" type="text" name="prodctName" placeholder="상품이름" onChange={onChange} value={productName} />
                        <DefaultDiv title="카테고리 1번" type="text" name="firstCategory" placeholder="상품이름" onChange={onChange} option={firstCategory} value={cate01} />
                        <DefaultDiv title="카테고리 2번" type="text" name="secondCategory" placeholder="상품이름" onChange={onChange} option={secondCategory} value={categoryCode} />
                    </Common.Container>

                    <Common.Container>
                        <Style.H2>상세 설정</Style.H2>
                        {/* <ProductInput title="가격" type="text" name="price" placeholder="상품가격" onChange={onChange} /> */}
                        {/* <ProductInput title="할인률" type="text" name="discount" placeholder="할인률(% 적용 숫자만 적어주세요)" onChange={onChange} /> */}
                        {/* <ProductInput title="판매 수량" type="text" name="sell" placeholder="판매 수량" onChange={onChange} /> */}
                        {/* <ProductInput title="재고" type="text" name="stock" placeholder="재고" onChange={onChange} /> */}
                        <ImageUpload title="상품썸네일" thumbnail={thumbnail} setThumbnail={setThumbnail} value={thumbnail} />
                        {
                            cate01 === "20"
                            && <PdfUpload title="PDF 등록" setPdf={setPdf} existingPdf={existingPdf} setExistingPdf={setExistingPdf} />
                        }
                        <Textarea title="상품상세설명" name="detailCotent" placeholder="상품상세설명" onChange={onChange} setProductContent={setProductContent} setImageCode={setImageCode} type="product" value={productContent} />
                        {/* <ProductOption title="상품 옵션 선택" setProductOption={setProductOption} /> */}
                        <Style.ProductRegister type='submit'>수정</Style.ProductRegister>
                    </Common.Container>
                </form>
            </>
    )
}

export default Modfiy;