import styled from "styled-components";

export const Container = styled.div`
    .title{
        text-align: center;
        margin-bottom: 50px;
        font-size: 40px;
        font-family: 'GmarketSansBold';
    }
    .createButton{
        text-align: right;
        a{
            background-color: #333;
            color: #fff;
            padding: 10px 20px 7px;
            border-radius: 5px;
            cursor: pointer;
        }
    }
    .board.t1{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 50px;
        align-items: center;
        margin-bottom: 100px;
        li{
            width: 100%;
            a{
                width: 100%;
                .imageBox{
                    display: inline-block;
                    height: 350px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img{
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                h3{
                    text-align: center;
                    line-height: 1.2;
                    margin-top: 30px;
                }
            }
        }
    }
    .board.t2{
        border-top: 1px solid #000f;
        border-bottom: 1px solid #000f;
        margin-top: 5px;
        margin-bottom: 50px;
        >a{
            padding: 15px 0px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
            :not(:last-child){
                border-bottom: 1px solid #ccc;
            }
            .day{
                font-size: 26px;
                font-weight: bold;
                color: #ccc;
            }
            .titles{
                flex: 1;
                text-align: left;
                margin-bottom: 0px;
                h4{
                    font-size: 26px;
                    font-weight: bold;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    line-height: 60px;
                }
                p{
                    font-size: 16px;
                    font-weight: 300;
                    color: #444;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    line-height: 1.2;
                }
            }
            img{
                width: 180px;
                height: 100px;
            }
            &:hover{
                background-color: #7ad5cc22;
                .day{
                    color: #555;
                }
                h4{
                    color: #06ab9f;
                }
            }
        }
    }
    .noBoard{
        line-height: 150px;
        text-align: center;
    }
    @media (max-width:800px) {
        .title{
            font-size: 30px;
            margin-bottom: 30px;
        }
        .board.t1{
            grid-template-columns: 1fr 1fr;
        }
        .board.t2>a img{
            display: none;
        }
        .board.t2>a .day{
            font-size: 20px;
        }
        .board.t2>a .titles h4{
            font-size: 20px;
            line-height: 40px;
        }
    }
    @media (max-width:400px) {
        .board.t1{
            grid-template-columns: 1fr;
        }
        .board.t2>a .day{
            font-size: 14px;
        }
        .board.t2>a .titles h4{
            font-size: 14px;
            line-height: 30px;
        }
    }
`