import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import LoginInput from 'components/input/Input';

import * as Style from "assets/styleComponent/faq/faq";

import bannerImage from 'assets/images/04.고객지원/banner_04.png';
import Banner from 'components/subBanner/Banner';
import { addFaq, getUpdateFaq, updateFaq } from 'api/board';

const FaqUpdate = () => {
    const { boardCode } = useParams();
    const [faq, setFaq] = useState(null);
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");

    useEffect(() => {
        getUpdateFaq({ i_board: boardCode }, setFaq);
    }, [])

    useEffect(() => {
        if (faq !== null) {
            setTitle(faq.question);
            setContent(faq.answer);
        }
    }, [faq])

    const onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        switch (name) {
            case "title":
                setTitle(value);
                break;
            case "content":
                setContent(value);
                break;
            default:
                break;
        }
    };

    return (
        <Style.CreateContainer>
            <Banner pageSubTitle={"Customer support"} pageTitle={"고객지원"} bannerImage={bannerImage} h2Color={'#a5c8cf'} h3Color={'#000'}></Banner>
            <div className="wrap">
                <div className="title">{sessionStorage.getItem('pageName')} 등록</div>
                <LoginInput type="text" name="title" value={title} placeholder="질문" onChange={onChange}></LoginInput>
                <textarea name="content" value={content} onChange={onChange} placeholder="답변"></textarea>

                <div className="button">
                    <button onClick={() => {
                        updateFaq({
                            question: title,
                            answer: content.replace(/\r?\n/g, "<br>"),
                            i_board: boardCode
                        })
                    }}>수정</button>
                </div>
            </div>
        </Style.CreateContainer>
    );
};

export default FaqUpdate;