import styled from "styled-components";

export const Container = styled.div`
    .title{
        text-align: center;
        margin-bottom: 50px;
        font-size: 40px;
        font-family: 'GmarketSansBold';
    }

    .createButton{
        text-align: right;
        a{
            background-color: #333;
            color: #fff;
            padding: 10px 20px 7px;
            border-radius: 5px;
            cursor: pointer;
            margin-bottom: 10px;
        }
    }
    .createButton.listButton{
        text-align: left;
        a,button{
            background-color: #06aa9f;
            color: #fff;
            margin-right: 5px;
            margin-top: 5px;
            padding: 10px 20px 7px;
            border-radius: 5px;
            cursor: pointer;
            margin-bottom: 10px;
        }
    }

    .wrap >ul{
        border-top: 2px solid #ccc;
        margin-bottom: 50px;
        li{
            border-bottom: 2px solid #ccc;
            width: 100%;
            .list{
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 15px;
                padding: 0px 15px;
                cursor: pointer;
                h3{
                    flex: 1;
                    line-height: 70px;
                    font-size: 16px;
                }
                i{
                    &:nth-child(1){
                        background-color: #06aa9f;
                        color: #fff;
                        padding: 5px;
                        font-size: 16px;
                        border-radius: 5px;
                    }
                    &:nth-child(3){
                        font-size: 18px;
                        color: #aaa;
                    }
                }
            }
            .content{
                padding: 0px 50px;
                max-height: 0px;
                overflow: hidden;
            }
            .content.on{
                padding: 30px 50px;
                max-height: unset;
                border-top: 2px solid #ccc;
            }
        }
    }

    @media (max-width:800px) {
        .title{
            font-size: 30px;
            margin-bottom: 30px;
        }
    }

    @media (max-width:600px) {
        .wrap >ul{
            li{
                .list{
                    h3{
                        font-size: 14px;
                    }
                    i{
                        &:nth-child(1){
                            font-size: 14px;
                        }
                        &:nth-child(3){
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
`

export const CreateContainer = styled.div`
    margin-bottom: 50px;

    .title{
        text-align: center;
        margin-bottom: 50px;
        font-size: 40px;
        font-family: 'GmarketSansBold';
    }
    
    p{
        display: inline-block;
        font-size: 16px;
    }

    input, textarea{
        width: 100%;
    }
    
    textarea{
        border: 1px solid #ddd;
        padding: 5px;
        resize: none;
        height: 300px;
        margin-top: 10px;
    }

    .button{
        text-align: center;
        margin-Top: 10px;
        
        button{
            background-color: #444;
            color: #fff;
            padding: 10px 30px;
            margin: 0 5px;
            border-radius: 5px;
            cursor: pointer;
        }
    }

    @media (max-width:800px) {
        .title{
            font-size: 30px;
            margin-bottom: 30px;
        }
    }
`