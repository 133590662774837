import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { getDetailBoard, deleteBoard } from 'api/board.js';

import adminCheck from 'utils/adminCheck';

import Loading from 'components/loding/Loading';

import * as Style from "assets/styleComponent/community/detail";
import Banner from 'components/subBanner/Banner';
import bannerImage from 'assets/images/04.고객지원/banner_04.png';

const NoticeDetail = () => {
    const { boardCode } = useParams();
    const nav = useNavigate();
    const [readDetail, setReadDetail] = useState(null);

    const boardDel = async () => {
        await deleteBoard({ boardType: "4020", i_board: boardCode });
        nav(`/pages/4020/1`);
    }

    useEffect(() => {
        getDetailBoard({ boardType: '4020', i_board: boardCode }, setReadDetail);
    }, [nav])

    return (
        readDetail === null
            ? <Loading />
            : <Style.Detail>
                <Banner pageSubTitle={"Customer support"} pageTitle={"고객지원"} bannerImage={bannerImage} h2Color={'#a5c8cf'} h3Color={'#000'}></Banner>
                <div className="wrap">
                    <h2>공지사항</h2>

                    <div className="title">
                        <ul>
                            <li>
                                <div>제목</div>
                                <div>{readDetail.detail.title}</div>
                            </li>
                            <li>
                                <div>작성자</div>
                                <div>{readDetail.detail.user_id}</div>
                            </li>
                        </ul>
                        <div>
                            <p><b>작성일</b>{readDetail.detail.create_date}</p>
                            <p><b>조회수</b>{readDetail.detail.view_up}</p>
                        </div>
                    </div>

                    <div className="content">
                        <p dangerouslySetInnerHTML={{ __html: readDetail.detail.content }}></p>
                    </div>

                    <Link to="/pages/4020/1" className="more">목록</Link>
                    {adminCheck(false) && <button onClick={boardDel}>삭제</button>}
                    {adminCheck(false) && <Link className="more" to={`/admin/product/modfiy/4020/${boardCode}`}>수정</Link>}

                    <div className="differentBoard">
                        <ul>
                            <li>
                                <div>다음글</div>
                                <div>
                                    <Link to={
                                        readDetail.next.length <= 0
                                            ? null
                                            : `/pages/4020/detail/${Number(readDetail.next[0].i_board)}`
                                    }>
                                        {
                                            readDetail.next.length <= 0
                                                ? "다음글이없습니다."
                                                : readDetail.next[0].title
                                        }
                                    </Link>
                                </div>
                            </li>
                            <li>
                                <div>이전글</div>
                                <div>
                                    <Link to={
                                        readDetail.pre.length <= 0
                                            ? null
                                            : `/pages/4020/detail/${Number(readDetail.pre[0].i_board)}`
                                    }>
                                        {
                                            readDetail.pre.length <= 0
                                                ? "이전글이없습니다."
                                                : readDetail.pre[0].title
                                        }
                                    </Link>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </Style.Detail>
    );
};

export default NoticeDetail;