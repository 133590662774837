import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { getCategory } from 'api/category';
import { logout } from 'api/logout';

import Loading from 'components/loding/Loading';

import styled from 'styled-components';

import logo from "assets/images/hd_logo.png"
import logo2 from "assets/images/ft_logo.png"

const Header = ({ user }) => {
    const nav = useNavigate();
    const location = useLocation();
    const [categorys, setCategorys] = useState(null);
    const [header, setHeader] = useState(false);
    const [isMobileMenu, setIsMobileMenu] = useState(false);
    const [mobileMenuNum, setMobileMenuNum] = useState(0);
    const [isMenuBox, setIsMenuBox] = useState(false);

    const pageSetSession = (pageCode, pageNameCode) => {
        for (let i = 0; i < categorys.length; i++) {
            if (pageCode === categorys[i].cate_code) {
                sessionStorage.setItem('pageCode', categorys[i].cate_code);
                for (let j = 0; j < categorys[i].lowCategory.length; j++) {
                    if (categorys[i].lowCategory[j].cate_code === pageNameCode) {
                        sessionStorage.setItem('pageName', categorys[i].lowCategory[j].cate);
                    }
                }
                return;
            }
        }
    }

    useEffect(() => {
        getCategory(setCategorys);
    }, [])

    useEffect(() => {
        setIsMobileMenu(false);
        setIsMenuBox(false);
        if (/.*pages.*/.test(location.pathname)) {
            setHeader(false);
        } else if (/.*login.*/.test(location.pathname)) {
            setHeader(false);
        } else {
            setHeader(true);
        }
    }, [nav])

    return (
        categorys === null
            ? <Loading />
            : < header>
                {/* <Login>
                    <div className="wrap">
                        {
                            user?.result === "ok"
                                ? <ul className='userNav'>
                                    <li><h2>{sessionStorage.getItem("userId")}님 로그인중</h2></li>
                                    <li style={{ cursor: "pointer" }} onClick={logout}>로그아웃</li>
                                    <li><Link to={"product/basket"}>장바구니</Link></li>
                                    <li><Link to={"myPage/order/1"}>마이페이지</Link></li>
                                    {
                                        sessionStorage.getItem("userId") === "admin" ||
                                            sessionStorage.getItem("userId") === "pkd" ||
                                            sessionStorage.getItem("userId") === "asd"
                                            ? <li><Link to={"admin"}>관리자</Link></li>
                                            : null
                                    }
                                    <li>
                                        <div className='notification'
                                            onClick={() => {
                                                setIsNotification(!isNotification);
                                                isNotification === true &&
                                                    updateNotification({ user_id: sessionStorage.getItem("userId") });
                                            }}>
                                            <i className="fa-sharp fa-solid fa-bell" ></i>
                                            <div className={checkNotification > 0 ? "on" : ""}>{checkNotification}</div>
                                        </div>
                                        <div className="notificationContainer">
                                            <ul className={isNotification === true ? "on" : ""}>
                                                {
                                                    notification.map((a, i) => {
                                                        return (
                                                            <li key={i} className={a.check_yn === "N" ? "" : "reading"}>
                                                                <Link to={a.content === "message" ? `/myPage/contact` : `/myPage/orderDetail/${a.orderCode}`}>
                                                                    <p>{a.content === "message" ? "새로운 메시지 도착" : a.content}</p>
                                                                    <div>{a.send_date}</div>
                                                                </Link>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                                : <ul className='userNav'>
                                    <li><Link to={"login"}>로그인</Link></li>
                                    <li><Link to={"loginRegister"}>회원가입</Link></li>
                                </ul>
                        }
                    </div>
                </Login> */}

                <Head style={header ? null : { backgroundColor: "#fff" }
                }>
                    <div className="wrap">
                        <div className="flexBox">
                            <a href="/"><h1><img src={header ? logo : logo2} alt="" /></h1></a>
                            <nav className={header ? `pc` : `pc pages`}>
                                <ul>
                                    {
                                        categorys?.map((a, i) => {
                                            return (
                                                <li key={i}>
                                                    {
                                                        a.cate === '제품소개' || a.cate === '설치사례'
                                                            ? <Link onClick={() => {
                                                                pageSetSession(a.cate_code, Number(`${a.cate_code}10`));
                                                            }} to={`/pages/${(a.cate_code)}10/1`}>{a.cate}</Link>
                                                            : <Link onClick={() => {
                                                                pageSetSession(a.cate_code, Number(`${a.cate_code}10`));
                                                            }} to={`/pages/${(a.cate_code)}10`}>{a.cate}</Link>
                                                    }
                                                    {
                                                        a.cate === '제품소개' || a.cate === '설치사례'
                                                            ? <ol>
                                                                {
                                                                    a.lowCategory?.map((b, j) => {
                                                                        return (
                                                                            <li key={j}><Link
                                                                                onClick={() => {
                                                                                    pageSetSession(a.cate_code, b.cate_code);
                                                                                }}
                                                                                style={header ? null : { color: "#000" }
                                                                                } to={`/pages/${b.cate_code}/1`} dangerouslySetInnerHTML={{ __html: b.cate }}></Link></li>
                                                                        )

                                                                    })
                                                                }
                                                            </ol>
                                                            : <ol>
                                                                {
                                                                    a.lowCategory?.map((b, j) => {
                                                                        return (
                                                                            b.cate === '뉴스/공지사항'
                                                                                ? <li key={j}><Link
                                                                                    onClick={() => {
                                                                                        pageSetSession(a.cate_code, b.cate_code);
                                                                                    }}
                                                                                    style={header ? null : { color: "#000" }
                                                                                    } to={`/pages/${b.cate_code}/1`} dangerouslySetInnerHTML={{ __html: b.cate }}></Link></li>
                                                                                : <li key={j}><Link
                                                                                    onClick={() => {
                                                                                        pageSetSession(a.cate_code, b.cate_code);
                                                                                    }}
                                                                                    style={header ? null : { color: "#000" }
                                                                                    } to={`/pages/${b.cate_code}`} dangerouslySetInnerHTML={{ __html: b.cate }}></Link></li>
                                                                        )
                                                                    })
                                                                }
                                                            </ol>
                                                    }
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </nav>

                            <div className="mobile">
                                <button onClick={() => { setIsMenuBox(!isMenuBox) }}>
                                    <i className="fa-solid fa-bars" style={header ? { color: "#fff" } : { color: "#000" }}></i>
                                </button>

                                <div className={
                                    isMenuBox === true
                                        ? "menuBox on"
                                        : "menuBox"
                                }>
                                    {/* {
                                        user?.result === "ok"
                                            ? <ul className='user flexBox'>
                                                <li>{sessionStorage.getItem("userId")}님 로그인중</li>
                                                <li onClick={logout}>로그아웃</li>
                                            </ul>
                                            : <ul className='user flexBox'>
                                                <li><Link to={"login"}>로그인</Link></li>
                                                <li><Link to={"loginRegister"}>회원가입</Link></li>
                                            </ul>
                                    }

                                    <ul className='nav'>
                                        <li>
                                            <Link to={"product/basket"}>
                                                <i className="fa-solid fa-basket-shopping"></i>
                                                장바구니
                                            </Link>
                                        </li>
                                        {
                                            user?.result === "ok" &&
                                            <li>
                                                <Link to={"myPage/order/1"}>
                                                    <i className="fa-solid fa-user"></i>
                                                    마이페이지
                                                </Link>
                                            </li>
                                        }
                                    </ul> */}

                                    <ul className='menu'>
                                        {
                                            categorys?.map((a, i) => {
                                                return (
                                                    <li key={i}>
                                                        {
                                                            a.lowCategory.length <= 0
                                                                ? <Link to={`/pages/${(a.cate_code)}10`}>{a.cate}</Link>
                                                                : <span
                                                                    onClick={() => {
                                                                        setIsMobileMenu(!isMobileMenu);
                                                                        setMobileMenuNum(i);
                                                                    }}
                                                                >{a.cate}</span>
                                                        }
                                                        <ol style={
                                                            isMobileMenu === true
                                                                ? mobileMenuNum === i
                                                                    ? { maxHeight: `${a.lowCategory.length * 40}px` }
                                                                    : { maxHeight: `0px` }
                                                                : { maxHeight: `0px` }
                                                        }>
                                                            {
                                                                a.lowCategory?.map((b, j) => {
                                                                    return (
                                                                        a.cate === '제품소개' || a.cate === '설치사례' || b.cate === '뉴스/공지사항'
                                                                            ? <li key={j}><Link
                                                                                onClick={() => {
                                                                                    pageSetSession(a.cate_code, b.cate_code);
                                                                                }}
                                                                                to={`/pages/${b.cate_code}/1`}>{b.cate}</Link></li>
                                                                            : <li key={j}><Link
                                                                                onClick={() => {
                                                                                    pageSetSession(a.cate_code, b.cate_code);
                                                                                }} to={`/pages/${b.cate_code}`}>{b.cate}</Link></li>
                                                                    )
                                                                })
                                                            }
                                                        </ol>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <MenuList className="pc">

                        <div className="wrap">
                            <div className="flexBox">
                                <a href="/"><h1><img src={logo} alt="" /></h1></a>

                                <nav className='pc'>
                                    <ul>
                                        {
                                            categorys?.map((a, i) => {
                                                return (
                                                    <li key={i}>
                                                        <Link to={`/product/products/${(a.cate_code)}/1`}>{a.cate}</Link>
                                                        <ol>
                                                            {
                                                                a.lowCategory?.map((b, j) => {
                                                                    return (
                                                                        <li key={j}><Link to={`/product/products/${b.cate_code}/1`} dangerouslySetInnerHTML={{ __html: b.cate }}></Link></li>
                                                                    )
                                                                })
                                                            }
                                                        </ol>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </nav>

                                <div className='pc len'>kr</div>
                            </div>
                        </div>

                    </MenuList> */}
                </Head>
            </header >
    );
};

const Login = styled.div`
    border-bottom: 1px solid #ccc;
    .userNav{
        display: flex;
        justify-content: end ;
        gap: 15px;
        >li{
            padding: 10px 0px; 
            position: relative;
            .notification{
                position: relative;
                cursor: pointer;
                i{
                    color: #555;
                }
                > div{
                    position: absolute;
                    top: -5px;
                    right: -5px;
                    background-color: red;
                    font-size: 10px;
                    border-radius: 50%;
                    width: 12px;
                    height: 12px;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    display: none;
                }
                >div.on{
                    display: flex;
                }
            }
            ul{
                position: absolute;
                width: 200px;
                height: 300px;
                max-width: 0px;
                max-height: 0px;
                transition: all .3s;
                top: 90%;
                right: 0;
                background-color: #fff;
                box-shadow: 2px 2px 5px #00000077;
                z-index: 5;
                display: flex;
                flex-direction: column;
                overflow-y: scroll;
                opacity: 0;
                ::-webkit-scrollbar {
                    width: 5px; 
                }
                ::-webkit-scrollbar-thumb {
                    background: #555; 
                    border-radius: 10px;
                }::-webkit-scrollbar-track {
                    background: #eee;  
                }
                li{
                    padding: 5px 0px;
                    border-bottom: 1px solid #ccc;
                    color: #000;
                    a{
                        width: 100%;
                    }
                    p{
                        font-size: 12px;
                    }
                    div{
                        text-align: right;
                        margin-top: 10px;
                        font-size: 10px;
                    }
                }
                li.reading{
                    p,div{
                        color: #888;
                    }
                }
            }
            ul.on{
                max-width: 200px;
                max-height: 300px;
                padding: 0px 5px;
                opacity: 1;
            }
        }
    }
    @media (max-width: 1200px) {
        display: none;
        ul.on{
                max-width: 200px;
                max-height: 0px;
                padding: 0px 5px;
                opacity: 1;
            }
    }
`

const MenuList = styled.div`
    position: absolute;
    top: 0;
    background-color: #00000077;
    height: 350px;
    width: 100%;
    a > h1 > img,.len{
        opacity: 0;
        visibility: hidden;
    }
`

const Head = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    padding: 15px 0px;
    overflow: hidden;
    height: 350px;
    max-height: 81px;
    transition: max-height .5s, background .5s .2s;
    &:hover{
        background-color: #00000077;
        transition: max-height .5s, background .5s;
        max-height: 350px;
    }
    a > h1 > img{}
    nav ul li{
        position: relative;
        z-index: 10;
    }

    nav ul > li > a{
        font-size: 18px;
        padding: 0px 50px;
        color: #fff;
        line-height: 50px;
    }
    nav.pages ul > li > a{
        color: #000;
    }
    nav ul li ol{
        position: absolute;
        top: 110%;
        left: 50%;
        transform: translateX(-50%);
        overflow-y: hidden;
        transition: max-height .5s;
        z-index: 5;
    }
    nav ul  li ol li{
        width: 100%;
    }
    nav ul li ol li a{
        width: 100%;
        font-size: 15px;
        white-space:nowrap;
        font-family: 'Roman';  
        line-height: 1.2;
        padding: 10px 10px;
        text-align: center;
        color: #fff;
    }
    nav ul li ol li a:hover{
        color: #aaa !important;
    }

    .mobile button i{
        font-size: 24px;
        cursor: pointer;
        line-height: 50px;
        color: #000;
    }

    .mobile .menuBox{
        position: fixed;
        top: 0;
        left: 0;
        background-color: #fff;
        width: 250px;
        max-width : 0px;
        overflow: hidden;
        transition: max-width .3s, opacity .2s;
        height: 100vh;
        z-index: 25;
        opacity: 0;
        box-shadow: -2px -2px 8px #00000077;
    }

    .mobile .menuBox.on{
        opacity: 1;
        max-width: 250px;
    }

    .mobile .menuBox ul.user{
        padding: 10px 5px;
        border-bottom: 1px solid #ccc;
    }

    .mobile .menuBox ul.user li{
        white-space: nowrap;
    }

    .mobile .menuBox ul.nav{
        display: flex;
    }

    .mobile .menuBox ul.nav li{
        flex: 1;
        text-align: center;
        padding: 10px 0px;
        border-bottom: 1px solid #ccc;
    }

    .mobile .menuBox ul.nav li a{
        display: flex;
        flex-direction: column;
        gap: 5px;
        white-space: nowrap;
    }

    .mobile .menuBox ul.nav li i{
        font-size: 20px;
    }

    .mobile .menuBox ul.nav li:nth-child(1){
        border-right: 1px solid #ccc;
    }

    .mobile .menuBox ul.menu{
        display: flex;
        flex-direction: column;
    }

    .mobile .menuBox ul.menu li{
        width: 100%;
    }

    .mobile .menuBox ul.menu > li > span,
    .mobile .menuBox ul.menu > li > a{
        display: inline-block;
        width: 100%;
        border-bottom: 1px solid #ccc;
        text-align: center;
        line-height: 40px;
        white-space: nowrap;
        cursor: pointer;
    }

    .mobile .menuBox ul.menu > li > ol{
        background-color: #eee;
        max-height: 0px;
        overflow: hidden;
        transition: max-height .5s;
    }

    .mobile .menuBox ul.menu > li > ol > li{
        width: 100%;
    }

    .mobile .menuBox ul.menu > li > ol > li > a{
        text-align: center;
        line-height:40px;
        width: 100%;
        white-space: nowrap;
    }

    .mobile .menuBox ul.menu > li > ol > li:not(:last-child) > a{
        border-bottom: 1px solid #ccc;
    }

    @media (max-width:1200px) {
        &:hover{
            max-height: 81px;
            background: none;
        }
    }
    @media (max-width:600px) {
        a > h1 > img{
            width: 130px;
        }
    }

`


export default Header;