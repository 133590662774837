import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as Style from "assets/styleComponent/faq/faq";
import bannerImage from 'assets/images/04.고객지원/banner_04.png';
import Banner from 'components/subBanner/Banner';
import { deleteFaq, getFaq } from 'api/board';
import adminCheck from 'utils/adminCheck';

const Faq = () => {
    const [list, setList] = useState(null);
    const [isContent, setIscontent] = useState(false);
    const [content, setContent] = useState(0);

    useEffect(() => {
        getFaq(setList);
    }, [])

    const faqOnOff = (num) => {
        num !== content
            ? setContent(num)
            : setIscontent(!isContent);
    }

    return (
        <Style.Container>
            <Banner pageSubTitle={"Customer support"} pageTitle={"고객지원"} bannerImage={bannerImage} h2Color={'#a5c8cf'} h3Color={'#000'}></Banner>
            <div className="wrap">
                <div className="title">{sessionStorage.getItem('pageName')}</div>

                {
                    adminCheck(false) && <div className="createButton">
                        <Link to={'/pages/4030/create'}>FAQ 등록</Link>
                    </div>
                }

                <ul>
                    {
                        list?.length > 0
                            ? list?.map((a, i) => {
                                return (
                                    <li key={i} >
                                        {
                                            adminCheck(false) && <div className="createButton listButton">
                                                <Link to={`/pages/4030/update/${a.i_board}`}>수정</Link>
                                                <button onClick={() => {
                                                    deleteFaq({ i_board: a.i_board })
                                                }}>삭제</button>
                                            </div>
                                        }
                                        <div className="list" onClick={() => { faqOnOff(i); }}>
                                            <i className="fa-solid fa-magnifying-glass"></i>
                                            <h3>{a.question}</h3>
                                            <i className="fa-solid fa-sort-down"></i>
                                        </div>
                                        <div className={
                                            isContent === true &&
                                                content === i ? 'content on' : 'content'
                                        }>
                                            <p dangerouslySetInnerHTML={{ __html: a.answer }}></p>
                                        </div>
                                    </li>
                                )
                            })
                            : <p style={{ borderBottom: '2px solid #ccc', textAlign: "center", lineHeight: "150px" }}>등록된 faq가 없습니다</p>
                    }
                </ul>
            </div>
        </Style.Container>
    );
};

export default Faq;