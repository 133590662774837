import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import moment from "moment/moment";

import { tokenCheck } from "api/token";
import { updateUserAccessCount } from "api/user";

import Header from "components/common/Header";
import Footer from "components/common/Footer";
import Error from "components/error/Error";
import NotFoundPage from "components/error/NotFoundPage";
import Intro from "pages/sub/intro/Intro";
import Patent from "pages/sub/intro/Patent";
import Loaction from "pages/sub/intro/Loaction";
import Product_01 from "pages/sub/product/Product_01";
import Mail from "pages/sub/customer/Mail";

import Detail from "pages/sub/product/Detail";
import Inquiry from "pages/sub/inquiry/Inquiry";
import Performance from "pages/sub/performance/Performance";
import PerformanceDetail from "pages/sub/performance/PerformanceDetail";
import Agency from "pages/sub/agency/Agency";
import AgencyDetail from "pages/sub/agency/AgencyDetail";
import Distributor from "pages/sub/distributor/Distributor";
import DistributorDetail from "pages/sub/distributor/DistributorDetail";
import Faq from "pages/sub/faq/Faq";
import FaqCreate from "pages/sub/faq/FaqCreate";

import Main from "pages/main/Main";
import Login from "pages/login/Login";
import Register from "pages/login/Register";
import Admin from "routers/Admin";
import Community from "./community/Community";

import "assets/css/common/common.css";
import FaqUpdate from "pages/sub/faq/FaqUpdate";
import NoticeDetail from "pages/community/NoticeDetail";

function App() {
    const nav = useNavigate();
    const location = useLocation();
    const [header, setHeader] = useState(true);
    const [user, setUser] = useState();
    const [orderData, setOrderData] = useState();
    const [cookies, setCookies] = useCookies();

    // 방문자 체크
    const accessCheck = async () => {
        const expires = moment().add('10', 'm').toDate();
        if (cookies.userCount !== 'true') {
            setCookies('userCount', true, { expires });
            updateUserAccessCount("나 등장~!");
        }
    }

    // 어드민페이지, 에러페이지 들어오면 헤더 삭제
    // 메인아니면 헤더 색깔 변경
    const pageCheck = () => {
        if (/.*admin.*/.test(location.pathname) || /.*error.*/.test(location.pathname)) {
            setHeader(false);
        } else if (/.*pages.*/.test(location.pathname)) {
            setHeader("pages");
        } else if (/.*community.*/.test(location.pathname)) {
            setHeader("community");
        } else {
            setHeader(true);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        accessCheck();
        pageCheck();
        tokenCheck(setUser);
    }, [nav])
    return (
        <>
            {header && <Header user={user} header={header}></Header>}
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/pages/1010" element={<Intro />} />
                <Route path="/pages/1020" element={<Patent />} />
                <Route path="/pages/1030" element={<Loaction />} />
                <Route path="/pages/4010" element={<Mail />} />
                <Route path="/pages/:categoryCode/:boardPage" element={<Product_01 />} />
                <Route path="/pages/:categoryCode/detail/:boardCode" element={<Detail />} />
                <Route path="/pages/4020/detail/:boardCode" element={<NoticeDetail />} />
                <Route path="/pages/4030" element={<Faq />} />
                <Route path="/pages/4030/create" element={<FaqCreate />} />
                <Route path="/pages/4030/update/:boardCode" element={<FaqUpdate />} />



                {/* <Route path="/pages/30/:boardPage" element={<Performance />} />
                <Route path="/pages/40/:boardPage" element={<Agency />} />
                <Route path="/pages/50/:boardPage" element={<Distributor />} />
                <Route path="/pages/60/1" element={<Inquiry />} /> */}

                <Route path="/pages/performanceDetail/:boardPage" element={<PerformanceDetail />} />
                <Route path="/pages/agencyDetail/:boardPage" element={<AgencyDetail />} />
                <Route path="/pages/distributorDetail/:boardPage" element={<DistributorDetail />} />

                <Route path="/admin/*" element={<Admin />} />
                <Route path='/login' element={<Login />} />
                <Route path='/loginRegister' element={<Register />} />
                <Route path="/community/*" element={<Community />} />

                {/* 에러 날때 밑에 파일로 보내줌 */}
                <Route path="/error" element={<Error />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
            {header && <Footer></Footer>}
        </>
    );
}

export default App;
