import React, { useEffect } from 'react';
import { useState } from 'react';

import { addBoard } from 'api/board.js';
import { getCategory } from 'api/category.js';

import createCode from 'utils/createCode';

import ImageUpload from 'components/admin/product/input/ImageUpload';
import PdfUpload from 'components/admin/product/input/PdfUpload';
import ProductInput from 'components/admin/product/input/Input';
import ProductOption from 'components/admin/product/input/ProductOption';
import ProductSelect from 'components/admin/product/input/Select';
import Textarea from 'components/admin/product/input/Textarea';

import * as Style from "assets/styleComponent/admin/product/register"
import * as Common from "assets/styleComponent/admin/common"

const Register = () => {
    const [productName, setProductName] = useState("");
    const [cate01, setCate01] = useState("");
    const [cate02, setCate02] = useState("");
    const [firstCategory, setFirstCategory] = useState([]);
    const [secondCategory, setSecondCategory] = useState([]);
    const [price, setPrice] = useState("");
    const [discount, setDiscount] = useState("");
    const [sell, setSell] = useState("");
    const [stock, setStock] = useState("");
    const [thumbnail, setThumbnail] = useState("");
    const [pdf, setPdf] = useState([]);
    const [productContent, setProductContent] = useState("");
    const [productOption, setProductOption] = useState("");
    const [imageCode, setImageCode] = useState([]);

    useEffect(() => {
        getCategory(setFirstCategory)
    }, [])

    useEffect(() => {
        // 카테고리 1번 선택하면 2번에 카테고리 1번 하위 카테고리 가져오기
        for (let i = 0; i < firstCategory.length; i++) {
            if (firstCategory[i].cate_code === Number(cate01)) {
                setSecondCategory(firstCategory[i].lowCategory);
            }
        }
        setCate02("");
    }, [cate01])

    const onSubmit = async (e) => {
        e.preventDefault();

        if (productName === "") {
            alert("제목 입력해주세요");
            return;
        } else if (cate01 === "선택해주세요" || cate01 === "") {
            alert("카테고리 1번 선택해주세요");
            return;
        } else if (cate02 === "선택해주세요" || cate02 === "") {
            alert("카테고리 2번 선택해주세요");
            return;
        }

        //에디터 실제로 이미지 있는지없는지 확인하고 없으면 지워줌
        let arr = imageCode;
        for (let i = 0; i < imageCode.length; i++) {
            for (let j = 0; j < arr.length; j++) {
                if (productContent.indexOf(imageCode[i]) === -1) {
                    if (arr[j] === imageCode[i]) {
                        arr.splice(j, 1);
                        j--;
                    }
                }
                setImageCode(arr);
            }
        }

        //날짜 정보
        const date = new Date();
        const yy = date.getFullYear().toString().substring(2);
        const mm = (("00" + (date.getMonth() + 1)).slice(-2));
        const dd = (("00" + date.getDate()).slice(-2));

        const data = {
            goods_code: createCode(),
            title: productName,
            cate_code: cate02 === "" || cate02 === "선택해주세요" ? cate01 : cate02,
            goods_img: thumbnail,
            content: productContent,
            image_code: imageCode,
            pdf: pdf,
            type: "product",
            date: `${yy}/${mm}/${dd}`
        }

        addBoard(data);
    }

    const onChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        switch (name) {
            case "prodctName":
                setProductName(value);
                break;
            case "firstCategory":
                setCate01(value);
                setSecondCategory([]);
                break;
            case "secondCategory":
                setCate02(value);
                break;
            case "price":
                setPrice(value);
                break;
            case "discount":
                setDiscount(value);
                break;
            case "stock":
                setStock(value);
                break;
            case "sell":
                setSell(value);
                break;

            default:
                break;
        }
    };
    return (
        <>
            <form onSubmit={onSubmit}>
                <Common.Container>
                    <Style.H2>기본 설정</Style.H2>
                    <ProductInput title="제목" type="text" name="prodctName" placeholder="개행하실려면 그 자리에 <br> 넣으시면됩니다" onChange={onChange} />
                    <ProductSelect title="카테고리 1번" type="text" name="firstCategory" placeholder="상품이름" onChange={onChange} option={firstCategory} />
                    <ProductSelect title="카테고리 2번" type="text" name="secondCategory" placeholder="상품이름" onChange={onChange} option={secondCategory} />
                </Common.Container>

                <Common.Container>
                    <Style.H2>상세 설정</Style.H2>
                    {/* <ProductInput title="가격" type="text" name="price" placeholder="상품가격" onChange={onChange} /> */}
                    {/* <ProductInput title="할인률" type="text" name="discount" placeholder="할인률(% 적용 숫자만 적어주세요)" onChange={onChange} /> */}
                    {/* <ProductInput title="판매 수량" type="text" name="sell" placeholder="판매 수량" onChange={onChange} /> */}
                    {/* <ProductInput title="재고" type="text" name="stock" placeholder="재고" onChange={onChange} /> */}
                    {
                        cate01 !== "40"
                        && <ImageUpload title="썸네일" thumbnail={thumbnail} setThumbnail={setThumbnail} />
                    }
                    {
                        cate01 === "20"
                        && <PdfUpload title="PDF 등록" setPdf={setPdf} />
                    }
                    <Textarea title="상세설명" name="detailCotent" placeholder="상세설명" onChange={onChange} setProductContent={setProductContent} setImageCode={setImageCode} type="product" />
                    {/* <ProductOption title="상품 옵션 선택" setProductOption={setProductOption} /> */}
                    <Style.ProductRegister type='submit'>등록</Style.ProductRegister>
                </Common.Container>
            </form>
        </>
    );
};

export default Register;