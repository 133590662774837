import React, { useEffect } from 'react'
import * as Style from "assets/styleComponent/intro/patent";

import Banner from 'components/subBanner/Banner';
import bannerImage from 'assets/images/01. 회사소개/banner_01.jpg'
import content from 'assets/images//01. 회사소개/2.특허 및 인증내역/설명문구 이미지.jpg'
import list01 from 'assets/images/01. 회사소개/2.특허 및 인증내역/특허 인증서 01.jpg'
import list02 from 'assets/images/01. 회사소개/2.특허 및 인증내역/특허 인증서 02.jpg'
import list03 from 'assets/images/01. 회사소개/2.특허 및 인증내역/특허 인증서 03.jpg'
import list04 from 'assets/images/01. 회사소개/2.특허 및 인증내역/특허 인증서 04.jpg'
import list05 from 'assets/images/01. 회사소개/2.특허 및 인증내역/특허 인증서 05.jpg'
import list06 from 'assets/images/01. 회사소개/2.특허 및 인증내역/특허 인증서 06.jpg'
import list07 from 'assets/images/01. 회사소개/2.특허 및 인증내역/특허 인증서 07.jpg'
import list08 from 'assets/images/01. 회사소개/2.특허 및 인증내역/인증내역 01.jpg'
import list09 from 'assets/images/01. 회사소개/2.특허 및 인증내역/인증내역 02.jpg'
import list10 from 'assets/images/01. 회사소개/2.특허 및 인증내역/인증내역 03.jpg'
import list11 from 'assets/images/01. 회사소개/2.특허 및 인증내역/인증내역 04.jpg'
import list12 from 'assets/images/01. 회사소개/2.특허 및 인증내역/인증내역 05.jpg'

export default function Patent() {
    return (
        <>
            <Banner pageSubTitle={'Company introduction'} pageTitle={'회사소개'} bannerImage={bannerImage}></Banner>
            <Style.Content>
                <div className="wrap">
                    <div className="content">
                        <img src={content} alt="" />
                    </div>
                    <h3>특허내역</h3>
                    <ul>
                        <li><img src={list01} alt="" /></li>
                        <li><img src={list02} alt="" /></li>
                        <li><img src={list03} alt="" /></li>
                        <li><img src={list04} alt="" /></li>
                        <li><img src={list05} alt="" /></li>
                        <li><img src={list06} alt="" /></li>
                        <li><img src={list07} alt="" /></li>
                        <li><p>외 18건</p></li>
                    </ul>
                    <h3>인증내역</h3>
                    <ul>
                        <li><img src={list08} alt="" /></li>
                        <li><img src={list09} alt="" /></li>
                        <li><img src={list10} alt="" /></li>
                        <li><img src={list11} alt="" /></li>
                        <li><img src={list12} alt="" /></li>
                    </ul>
                </div>
            </Style.Content>
        </>
    )
}
