import React from 'react';
import styled from "styled-components";

const DefaultDiv = ({ title, option, value }) => {
    const formetValue = () => {
        let val = '';
        for (let i = 0; i < option.length; i++) {
            if (option[i].cate_code === Number(value)) {
                val = option[i].cate;
            }
        }
        return val;
    }
    return (
        <Label>
            <span>{title}</span>
            <div>{formetValue()}</div>
        </Label >
    );
};

const Label = styled.label`
    display: flex;
    align-items: center;
    padding: 10px 0px;
    border-bottom: 1px solid #ddd;
    span{
        display: inline-block;
        width: 150px;
    }
    > div{
        line-height: 30px;
        padding: 0px 10px;
        font-weight: bold;
    }
`;

export default DefaultDiv;