import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { getBoard } from 'api/board.js';

import adminCheck from 'utils/adminCheck'

import Paging from 'components/board/Paging';
import Loading from 'components/loding/Loading';
import Searching from 'components/board/Searching';
import Banner from 'components/subBanner/Banner';

import * as Style from "assets/styleComponent/community/notice";

import bannerImage from 'assets/images/04.고객지원/banner_04.png';

const Notice = () => {
    const nav = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { categoryCode, boardPage } = useParams();
    const [boardList, setBoardList] = useState(null);

    useEffect(() => {
        getBoard({ boardType: categoryCode, boardPage: boardPage }, setBoardList);
    }, [nav])

    console.log(boardList);
    // useEffect(() => {
    //     const data = {
    //         boardPage: boardPage,
    //         boardType: "notice"
    //     }
    //     if (searchParams.get("search") !== null) {
    //         data.search = searchParams.get("search");
    //     }
    //     getBoard(data, setBoardList);
    // }, [nav, searchParams.get("search")])

    return (
        boardList === null
            ? <Loading />
            : <Style.Contaienr>
                <Banner pageSubTitle={"Customer support"} pageTitle={"고객지원"} bannerImage={bannerImage} h2Color={'#a5c8cf'} h3Color={'#000'}></Banner>
                <div className="wrap">
                    <h2>뉴스/공지사항</h2>

                    {
                        adminCheck(false) && <div className="createButton">
                            <Link to={'/admin/product/register'}>글작성</Link>
                        </div>
                    }

                    {/* <div className="flexBox">
                        <Searching board={boardList.list} setBoardList={setBoardList} searchType={"notice"} />
                        {adminCheck(false) && <Link className='write' to={"/community/write"}>글쓰기</Link>}
                    </div> */}
                    <Style.Board>
                        <ul className='title'>
                            <li>번호</li>
                            <li>제목</li>
                            <li>글쓴이</li>
                            <li>작성일</li>
                            <li>조회수</li>
                        </ul>
                        {
                            boardList.list.length > 0
                                ? boardList.list.map((a, i) => {
                                    return (
                                        <ul key={i} className='list'>
                                            <li>{a.i_board}</li>
                                            <li><Link to={`/pages/${categoryCode}/detail/${a.i_board}`}>{a.title}</Link></li>
                                            <li>{a.user_id}</li>
                                            <li>{a.create_date}</li>
                                            <li>{a.view_up}</li>
                                        </ul>
                                    )
                                })
                                : <p>등록된 게시글이 없습니다</p>
                        }
                    </Style.Board>

                    <Paging boardPage={boardPage} boardLength={boardList?.count.page_count} url={"/pages/4020"} />
                </div>
            </Style.Contaienr>
    );
};

export default Notice;