import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';

import { getBoard } from 'api/board';
import Banner from 'components/subBanner/Banner';
import Pageing from 'components/board/Paging';

import * as Style from "assets/styleComponent/product/product";

import bannerImage01 from 'assets/images/02. 제품소개/banner_02.jpg';
import bannerImage02 from 'assets/images/03.설치사례/banner_03.png';
import Notice from 'pages/community/Notice';
import adminCheck from 'utils/adminCheck';

const Product = () => {
    const [board, setBoard] = useState(null);
    const nav = useNavigate();
    const { categoryCode, boardPage } = useParams();

    const newDate = (date) => {
        const dateParts = date.split('/');
        const month = dateParts[1];
        const day = dateParts[0];
        return month + '.' + day;
    }

    useEffect(() => {
        getBoard({ boardType: categoryCode, boardPage: boardPage }, setBoard);
    }, [nav])

    return (
        categoryCode === "4020"
            ? <Notice />
            : <Style.Container>
                <Banner pageSubTitle={
                    sessionStorage.getItem('pageCode') === '20'
                        ? 'Sales presentation'
                        : 'Installation case'
                } pageTitle={
                    sessionStorage.getItem('pageCode') === '20'
                        ? "제품소개"
                        : "설치사례"
                } bannerImage={
                    sessionStorage.getItem('pageCode') === '20'
                        ? bannerImage01
                        : bannerImage02
                } h2Color={'#e9f0df'} h3Color={'#fff'}></Banner>
                <div className="wrap">
                    <div className="title">{sessionStorage.getItem('pageName')}</div>
                    {
                        adminCheck(false) && <div className="createButton">
                            <Link to={'/admin/product/register'}>글작성</Link>
                        </div>
                    }

                    {
                        board !== null
                            ? board?.list.length > 0
                                ? sessionStorage.getItem('pageCode') === '20'
                                    ? <ul className='board t1'>
                                        {board?.list.map((a, i) => {
                                            return (
                                                <li key={i}>
                                                    <Link to={`/pages/${categoryCode}/detail/${a.i_board}`}>
                                                        <div className="imageBox">
                                                            <img src={a.thumbnail} alt="" />
                                                        </div>
                                                        <h3 dangerouslySetInnerHTML={{ __html: a.title }}></h3>
                                                    </Link>
                                                </li>
                                            )
                                        })
                                        }
                                    </ul>
                                    : <div className="board t2">
                                        {
                                            board?.list.map((a, i) => {
                                                return (
                                                    <Link key={i} to={`/pages/${categoryCode}/detail/${a.i_board}`}>
                                                        <div className="day">{newDate(a.create_date)}</div>
                                                        <div className="titles">
                                                            <h4>
                                                                {
                                                                    a.title.length > 10
                                                                        ? a.title.substr(0, 10) + '...'
                                                                        : a.title
                                                                }
                                                            </h4>
                                                        </div>
                                                        <img src={a.thumbnail} alt="" />
                                                    </Link>
                                                )
                                            })
                                        }
                                    </div>
                                : <p className='noBoard'>등록된 게시글이 없습니다.</p>
                            : <p className='noBoard'>등록된 게시글이 없습니다.</p>
                    }

                    {
                        board?.list.length > 0 &&
                        <Pageing boardPage={boardPage} boardLength={board?.count.page_count} url={`/pages/${categoryCode}`} />
                    }
                </div>
            </Style.Container >
    );
};
export default Product;